import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { HyposmiaSeverity, YesNo, YesNoUnknown } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HyposmiaBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.hyposmia === YesNo.YES} title="Sense of Smell">
      <YesNoQuestion
        response={responses.hyposmia}
        text="Have you ever had trouble smelling odors (anosmia or hyposmia)?"
      />
      <YesNoQuestion
        response={responses.hyposmiaCurrent}
        text="Do you still find it hard to smell things?"
      />
      <EnumQuestion
        choices={{
          [HyposmiaSeverity.SMELL_MOST_THINGS]: 'I can smell most things',
          [HyposmiaSeverity.SMELL_NOXIOUS_THINGS_ONLY]: 'I can only smell really strong, really bad smells',
          [HyposmiaSeverity.ANOSMIA]: 'I can\'t smell anything',
        }}
        highlight={responses.hyposmiaSeverity === HyposmiaSeverity.ANOSMIA && flags.includes(RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT)}
        response={responses.hyposmiaSeverity}
        text="How hard is it for you to smell things?"
      />
      <YesNoQuestion
        highlight={(responses.hyposmiaNeedsSmellForWork === YesNoUnknown.YES || responses.hyposmiaNeedsSmellForWork === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT)}
        response={responses.hyposmiaNeedsSmellForWork}
        text="Do you need to be able to smell dangers for your job?"
      />
      <YesNoQuestion
        highlight={responses.hyposmiaCanSmellWorkDangers === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT)}
        response={responses.hyposmiaCanSmellWorkDangers}
        text="Can you smell the chemicals or dangers that are part of your work?"
      />
    </QuestionSection>
  );
}
