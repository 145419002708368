import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HeartAttackBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.heartAttack === YesNo.YES} title="Heart Attacks">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE) && responses.heartAttack === YesNo.YES}
        response={responses.heartAttack}
        text="Have you ever had a heart attack?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_MI)}
        response={responses.heartAttackLessThan3YearsAgo}
        text="Was your most recent heart attack less than three (3) years ago?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_CARDIAC_PROBLEM)}
        response={responses.heartAttackCardiacHospitalVisitLastYear}
        text="Have you gone to the hospital or had to stay there because of heart problems in the last year?"
      />
      {responses.heartAttack === YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_USE_OF_MEDS_FOR_ACUTE_ANGINA)}
          response={responses.anginaRescueMedUseLastYear}
          text="Have you had to take medicine for sudden chest pain, like nitroglycerin or beta blockers, in the last year?"
        />
      )}
      {responses.heartAttack === YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.ISCHEMIC_HEART_CONDITION_C_ADDITIONAL_SX)}
          response={responses.heartConditionAdditionalSxLast2Years}
          text="In the last two (2) years, have you ever felt chest pain, had trouble breathing, or felt really tired while working?"
        />
      )}
      {responses.heartAttack === YesNo.YES && (
        <YesNoQuestion
          highlight={responses.heartCondition150MinutesActivityPerWeek === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartCondition150MinutesActivityPerWeek}
          text="Do you get at least 150 minutes of physical activity per week?"
        />
      )}
      {responses.heartAttack === YesNo.YES && (
        <YesNoQuestion
          highlight={responses.heartConditionHighCholesterol === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartConditionHighCholesterol}
          text="Do you have high cholesterol?"
        />
      )}
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_CORONARY_ARTERY_PROCEDURE)}
        response={responses.heartAttackCoronaryArteryProcedureLast6Months}
        text="Have you had any coronary artery bypass graft (CABG) or percutaneous transluminal coronary angioplasty (PTCA) in the last six (6) months?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.PHYSICAL_RESTRICTIONS_FOR_MI)}
        response={responses.heartAttackRestrictionsRemoved}
        text="Has your doctor told you that you're cleared to do all kinds of physical activity and return to work without restrictions?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_MI)}
        response={responses.heartAttackWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your past heart attack(s)?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.MI_S_CARDIAC_STRESS_TEST)}
        response={responses.heartAttackHadStressTest}
        text="Since your heart attack, have you had an exercise (treadmill or bike) stress test?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.FAILED_CARDIAC_STRESS_TEST)}
        response={responses.heartAttackStressTestFailed}
        text="During your exercise stress test, did they have to stop it early because you felt really tired, had chest pain, or had any heart problems?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.CARDIAC_STRESS_TEST_INDICATING_ARRHYTHMIA_OR_ISCHEMIA)}
        response={responses.heartAttackStressTestShowedArrhythmiaOrIschemia}
        text="Did your exercise stress test show signs of arrhythmia or ischemia (not enough blood flow to the heart)?"
      />
      {responses.heartAttack === YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CARDIAC_ISSUES_IN_RESPIRATOR)}
          response={responses.heartConditionRespiratorUseExpectedOk}
          text="Do you think you can use a respirator, or will it be too difficult because it makes breathing harder and you have a heart condition?"
        />
      )}
    </QuestionSection>
  );
}
