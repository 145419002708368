import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function EmphysemaBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.emphysema === YesNo.YES} title="Emphysema">
      <YesNoQuestion
        response={responses.emphysema}
        text="Have you ever had emphysema?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPHYSEMA_SX_DURING_ADL)}
        response={responses.emphysemaSxDuringAdl}
        text="Does your emphysema bother you when you do everyday things like chores, exercising, or walking up stairs?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_EMPHYSEMA)}
        response={responses.emphysemaWorseLastYear}
        text="Has your emphysema gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_EMPHYSEMA)}
        response={responses.emphysemaSeverity}
        text="How bad do you and your doctor think your emphysema is?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_EMPHYSEMA_ISSUES_IN_RESPIRATOR)}
        response={responses.emphysemaPriorRespiratorUseNotOk}
        text="Has your emphysema ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_EMPHYSEMA_ISSUES_IN_RESPIRATOR)}
        response={responses.emphysemaFutureRespiratorUseExpectedNotOk}
        text="Do you think your emphysema will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_EMPHYSEMA)}
        response={responses.emphysemaWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your emphysema?"
      />
      <YesNoQuestion
        response={responses.emphysemaMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPHYSEMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.emphysemaMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_EMPHYSEMA)}
        response={responses.emphysemaHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your emphysema?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_EMPHYSEMA_IMPACTING_WORK)}
        response={responses.emphysemaImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your emphysema?"
      />
    </QuestionSection>
  );
}
