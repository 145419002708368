import { YesNo, YesNoUnknown } from '../lib/general';

import { EnumQuestion } from './EnumQuestion';

export function YesNoQuestion({
  highlight = false,
  text,
  response,
}: {
  highlight?: boolean;
  text: string;
  response: YesNo | YesNoUnknown | null;
}) {
  return <EnumQuestion
    choices={{
      [YesNo.NO]: 'NO',
      [YesNoUnknown.UNKNOWN]: 'UNKNOWN',
      [YesNo.YES]: 'YES',
    }}
    highlight={highlight}
    text={text}
    response={response}
  />
}
