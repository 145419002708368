import { WithAuthenticatorProps, withAuthenticator } from '@aws-amplify/ui-react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import * as Sentry from '@sentry/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { differenceInYears } from 'date-fns/differenceInYears';
import { format } from 'date-fns/format';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { LoadingIcon } from '../components/LoadingIcon';
import { JobRoleSummary } from '../components/JobRoleSummary';
import { RespiratorMedicalEvaluationFlag, flagText } from '../lib/flags';
import { ArrhythmiaBlock } from '../components/ArrhythmiaBlock';
import { SmokingHistoryBlock } from '../components/SmokingHistoryBlock';
import { SeizuresBlock } from '../components/SeizuresBlock';
import { DiabetesBlock } from '../components/DiabetesBlock';
import { AllergiesBlock } from '../components/AllergiesBlock';
import { ClaustrophobiaBlock } from '../components/ClaustrophobiaBlock';
import { HyposmiaBlock } from '../components/HyposmiaBlock';
import { AsbestosisBlock } from '../components/AsbestosisBlock';
import { AsthmaBlock } from '../components/AsthmaBlock';
import { ChronicBronchitisBlock } from '../components/ChronicBronchitisBlock';
import { EmphysemaBlock } from '../components/EmphysemaBlock';
import { PneumoniaBlock } from '../components/PneumoniaBlock';
import { TuberculosisBlock } from '../components/TuberculosisBlock';
import { SilicosisBlock } from '../components/SilicosisBlock';
import { PneumothoraxBlock } from '../components/PneumothoraxBlock';
import { LungCancerBlock } from '../components/LungCancerBlock';
import { BrokenRibsBlock } from '../components/BrokenRibsBlock';
import { SobBlock } from '../components/SobBlock';
import { CoughBlock } from '../components/CoughBlock';
import { WheezeBlock } from '../components/WheezeBlock';
import { ChestPainBlock } from '../components/ChestPainBlock';
import { PriorRespiratorUseBlock } from '../components/PriorRespiratorUseBlock';
import { PriorRespiratorUseEyeIrritationBlock } from '../components/PriorRespiratorUseEyeIrritationBlock';
import { PriorRespiratorUseSkinProblemsBlock } from '../components/PriorRespiratorUseSkinProblemsBlock';
import { PriorRespiratorUseAnxietyBlock } from '../components/PriorRespiratorUseAnxietyBlock';
import { PriorRespiratorUseFatigueBlock } from '../components/PriorRespiratorUseFatigueBlock';
import { PriorRespiratorUseOtherProblemsBlock } from '../components/PriorRespiratorUseOtherProblemsBlock';
import { OtherChestInjuriesSurgeriesBlock } from '../components/OtherChestInjuriesSurgeriesBlock';
import { OtherLungProblemsBlock } from '../components/OtherLungProblemsBlock';
import { OtherLungSymptomsBlock } from '../components/OtherLungSymptomsBlock';
import { HeartMurmurBlock } from '../components/HeartMurmurBlock';
import { HeartAttackBlock } from '../components/HeartAttackBlock';
import { StrokeBlock } from '../components/StrokeBlock';
import { AnginaBlock } from '../components/AnginaBlock';
import { HeartFailureBlock } from '../components/HeartFailureBlock';
import { HypertensionBlock } from '../components/HypertensionBlock';
import { HeartburnBlock } from '../components/HeartburnBlock';
import { OtherHeartProblemsBlock } from '../components/OtherHeartProblemsBlock';
import { OtherHeartSymptomsBlock } from '../components/OtherHeartSymptomsBlock';
import { MedicationsBlock } from '../components/MedicationsBlock';
import { VisionLossBlock } from '../components/VisionLossBlock';
import { VisionCorrectionBlock } from '../components/VisionCorrectionBlock';
import { ColorBlindnessBlock } from '../components/ColorBlindnessBlock';
import { OtherVisionProblemsBlock } from '../components/OtherVisionProblems';
import { HearingLossBlock } from '../components/HearingLossBlock';
import { EarInjuriesBlock } from '../components/EarInjuriesBlock';
import { HearingAidsBlock } from '../components/HearingAidsBlock';
import { OtherHearingProblemsBlock } from '../components/OtherHearingProblemsBlock';
import { BackProblemsBlock } from '../components/BackProblemsBlock';
import { WeaknessBlock } from '../components/WeaknessBlock';
import { RangeOfMotionBlock } from '../components/RangeOfMotionBlock';
import { OtherMusculoskeletalProblemsBlock } from '../components/OtherMusculoskeletalProblemsBlock';
import { SpeakToProviderBlock } from '../components/SpeakToProviderBlock';
import { InlineRadioField } from '../components/InlineRadioField';
import { RespiratorUsageScenario, RespiratoryHazard } from '../lib/general';
import { StackedCheckboxField } from '../components/StackedCheckboxField';
import { Button } from '../components/Button';
import { TextAreaField } from '../components/TextAreaField';
import { RespiratorMedicalEvaluationStatus, RespiratorType, Risk, Sex } from '../lib/general';
import { Chart, QueryApiResponse, RespiratorMedicalEvaluationReview } from '../types/general';
import { ImplantableCardiacDevicesBlock } from '../components/ImplantableCardiacDevicesBlock';

const respiratorTypeLabels = {
  [RespiratorType.AIR_PURIFYING_DISPOSABLE]: 'Disposable Respirator (N95, P100, etc.)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE]: 'Full-Face Air Purifying (APR)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE]: 'Partial-Face Air Purifying (APR)',
  [RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE]: 'Full-Face Powered Air Purifying (PAPR)',
  [RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE]: 'Partial-Face Powered Air Purifying (PAPR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE]: 'Full-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE]: 'Partial-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_SCBA]: 'Self-Contained Breathing Apparatus (SCBA)',
};

const usageScenarioLabels = {
  [RespiratorUsageScenario.ESCAPE]: 'Emergency Escape',
  [RespiratorUsageScenario.FIREFIGHTING]: 'Firefighting',
  [RespiratorUsageScenario.RESCUE]: 'Rescue Team (Rescue Role)',
  [RespiratorUsageScenario.RESCUE_OVERSIGHT]: 'Rescue Team (Oversight Role)',
  [RespiratorUsageScenario.ROUTINE]: 'Routine Use',
  [RespiratorUsageScenario.SITUATIONAL]: 'Situational Use',
};

const respiratoryHazardLabels = {
  [RespiratoryHazard.CHEMICALS]: 'Chemicals',
  [RespiratoryHazard.DUST]: 'Dust',
  [RespiratoryHazard.FUMES]: 'Fumes',
  [RespiratoryHazard.INFECTIOUS_AGENTS]: 'Infectious Agents',
  [RespiratoryHazard.OXYGEN_DEFICIENT]: 'Oxygen-Deficient',
  [RespiratoryHazard.OXYGEN_ENRICHED]: 'Oxygen-Enriched',
  [RespiratoryHazard.SENSITIZERS]: 'Sensitizers',
  [RespiratoryHazard.SMOKE]: 'Smoke',
  [RespiratoryHazard.VAPORS]: 'Vapors',
};

type ChartResponse = {
  chart: Chart;
};

export function ChartPageInner({ signOut }: WithAuthenticatorProps) {
  const [chart, setChart] = useState<Chart | undefined>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [status, setStatus] = useState<RespiratorMedicalEvaluationStatus | null>(null);
  const [approvedTypes, setApprovedTypes] = useState<RespiratorType[]>([]);
  const [chartComments, setChartComments] = useState('');
  const [publicComments, setPublicComments] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [reviews, setReviews] = useState<RespiratorMedicalEvaluationReview[] | null>(null);

  const respiratorMedicalEvaluation = getReviewableRme(chart);

  useEffect(() => {
    async function fetchReviews() {
      if (respiratorMedicalEvaluation !== null) {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};

        if (accessToken === undefined) {
          return;
        }

        try {
          const result = await fetch(
            `${import.meta.env.VITE_API_BASE_URL}/respirator-medical-evaluation-reviews?respiratorMedicalEvaluationId=${respiratorMedicalEvaluation.id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken.toString()}`,
              },
            },
          );

          if (result.status !== 200) {
            throw new Error('Failed fetch.');
          }
  
          const payload = await result.json() as QueryApiResponse<RespiratorMedicalEvaluationReview>;

          setReviews(payload.data);
        } catch (err) {
          Sentry.captureException(err);
        }
      }
    }

    void fetchReviews();
  }, [respiratorMedicalEvaluation]);

  useEffect(() => {
    async function fetchChart() {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};

      if (accessToken === undefined) {
        setLoading(false);
        return;
      }

      try {
        const result = await fetch(
          `${import.meta.env.VITE_API_BASE_URL}/charts/${params.id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken.toString()}`,
            },
          },
        );

        if (result.status !== 200) {
          throw new Error('Failed fetch.');
        }

        const payload = await result.json() as ChartResponse;

        setChart(payload.chart);
        setLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        setLoading(false);
      }
    }

    void fetchChart();
  }, [params.id]);

  useEffect(() => {
    if (respiratorMedicalEvaluation === null) {
      return;
    }

    if (status === RespiratorMedicalEvaluationStatus.APPROVED) {
      setApprovedTypes(respiratorMedicalEvaluation.respiratorTypeUsages.map((rtu) => rtu.respiratorType));
    } else if (status === RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW) {
      setApprovedTypes([]);
      setPublicComments('');
    } else if (status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
        || status === RespiratorMedicalEvaluationStatus.DEFERRED) {
      setApprovedTypes([]);
    }
  }, [respiratorMedicalEvaluation, status]);

  if (chart === undefined) {
    if (loading) {
      return <LoadingIcon />;
    } else {
      return <div className="text-red-500">Error.</div>;
    }
  }

  if (respiratorMedicalEvaluation === null) {
    return <div className="text-red-500">Error: nothing to review.</div>
  }

  const { dateOfBirth, firstName, lastName, middleName, sex } = chart.demographics;

  const responses = respiratorMedicalEvaluation.riskAssessment.context.responses;
  const riskAssessment = respiratorMedicalEvaluation.riskAssessment;
  const flags = riskAssessment.flags;

  const decisionOptions = [
    {
      label: 'Approved',
      value: RespiratorMedicalEvaluationStatus.APPROVED,
    },
  ];

  if (respiratorMedicalEvaluation.respiratorTypeUsages.length > 1) {
    decisionOptions.push({
      label: 'Partially Approved',
      value: RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED,
    });
  }

  if (respiratorMedicalEvaluation.status !== RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW) {
    decisionOptions.push({
      label: 'Interview Needed',
      value: RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW,
    });
  }

  decisionOptions.push(
    {
      label: 'Deferred',
      value: RespiratorMedicalEvaluationStatus.DEFERRED,
    },
  );

  let isValid = true;

  if (status === null
      || chartComments.length === 0
      || (
        status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
        && (approvedTypes.length === 0 || approvedTypes.length === respiratorMedicalEvaluation.respiratorTypeUsages.length)
      )
  ) {
    isValid = false;
  }

  async function trySubmit() {
    if (respiratorMedicalEvaluation === null || !isValid) {
      return;
    }

    const { accessToken } = (await fetchAuthSession()).tokens ?? {};

    if (accessToken === undefined) {
      return;
    }

    setError(false);
    setSubmitting(true);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/respirator-medical-evaluation-reviews`,
        {
          headers: {
            Authorization: `Bearer ${accessToken.toString()}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            approvedRespiratorTypes: status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
              ? approvedTypes
              : [],
            chartComments,
            evaluation: {
              id: respiratorMedicalEvaluation.id,
            },
            publicComments,
            status,
          }),
        },
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error();  // Unsuccessful
      }

      setSubmitting(false);
      navigate('/');
    } catch (err) {
      Sentry.captureException(err);
      setError(true);
      setSubmitting(false);
    }
  }

  return (
    <div className="p-8">
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-row justify-between">
          <div>
            <Link className="text-[#3ac8aa] font-bold text-lg" to="/">&laquo; Back to Home</Link>
          </div>
          <button
            type="button"
            className="rounded-md bg-[#3ac8aa] px-2.5 py-1.5 text-sm font-semibold text-teal-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500"
            onClick={signOut}
          >
            Sign Out
          </button>
        </div>
        <h1 className="font-display font-bold text-2xl mt-8" style={{
          background:  'linear-gradient(to bottom, #9238ff, #e501ff)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>{`${lastName}, ${firstName} ${middleName}`.toUpperCase()}</h1>
        <div className="text-white font-bold">
          {sex === Sex.MALE ? 'Male' : 'Female'} &bull;{' '}
          {differenceInYears(new Date(), dateOfBirth)} years old &bull;{' '}
          {`${Math.floor(responses.height / 12)}'${responses.height % 12}"`} &bull;{' '}
          {responses.weight} lbs. &bull;{' '}
          <span className={flags.includes(RespiratorMedicalEvaluationFlag.HIGH_BMI) ? 'bg-orange-300 text-black': ''}>BMI {Math.round((responses.weight / 2.20462) / Math.pow(responses.height / 39.3701, 2) * 10) / 10}</span>
        </div>
        <div className="text-white font-bold">
          Phone: {chart.contactInfo.phoneNumber.substring(0, 3)}-{chart.contactInfo.phoneNumber.substring(3, 6)}-{chart.contactInfo.phoneNumber.substring(6)}
        </div>
        <div className="mt-4 text-white text-xl font-bold">Risk Assessment</div>
        <div className="rounded-md mt-2 p-6 bg-gray-50">
          Risk:{' '}
          <span className="font-bold text-xl">
            {riskAssessment.risk === Risk.LOW
              ? <span className="text-green-500">LOW</span>
              : riskAssessment.risk === Risk.MEDIUM
                ? <span className="text-orange-500">MEDIUM</span>
                : <span className="text-red-500">HIGH</span>
            }
          </span>
          <ul className="list-disc text-sm ml-4">
            {flags.map((flag) => (
              <li key={flag}>{flagText[flag]}</li>
            ))}
          </ul>
        </div>
        <div className="mt-4 text-white text-xl font-bold">Job Role & Respirators</div>
        <div className="rounded-md bg-gray-50 mt-2 p-6">
          <JobRoleSummary
            flags={flags}
            jobRole={respiratorMedicalEvaluation.questionnaire.jobRole}
            respiratorTypeUsages={respiratorMedicalEvaluation.respiratorTypeUsages}
          />
        </div>
        <div className="mt-4 text-white text-xl font-bold">Questionnaire</div>
        <div className="rounded-md mt-2 p-2 bg-gray-50 flex flex-col gap-2 items-stretch">
          <PriorRespiratorUseBlock flags={flags} responses={responses} />
          <PriorRespiratorUseEyeIrritationBlock flags={flags} responses={responses} />
          <PriorRespiratorUseSkinProblemsBlock flags={flags} responses={responses} />
          <PriorRespiratorUseAnxietyBlock flags={flags} responses={responses} />
          <PriorRespiratorUseFatigueBlock flags={flags} responses={responses} />
          <PriorRespiratorUseOtherProblemsBlock flags={flags} responses={responses} />
          <SmokingHistoryBlock flags={flags} responses={responses} />
          <SeizuresBlock flags={flags} responses={responses} />
          <DiabetesBlock flags={flags} responses={responses} />
          <AllergiesBlock flags={flags} responses={responses} />
          <ClaustrophobiaBlock flags={flags} responses={responses} />
          <HyposmiaBlock flags={flags} responses={responses} />
          <AsbestosisBlock flags={flags} responses={responses} />
          <AsthmaBlock flags={flags} responses={responses} />
          <ChronicBronchitisBlock flags={flags} responses={responses} />
          <EmphysemaBlock flags={flags} responses={responses} />
          <PneumoniaBlock flags={flags} responses={responses} />
          <TuberculosisBlock flags={flags} responses={responses} />
          <SilicosisBlock flags={flags} responses={responses} />
          <PneumothoraxBlock flags={flags} responses={responses} />
          <LungCancerBlock flags={flags} responses={responses} />
          <BrokenRibsBlock flags={flags} responses={responses} />
          <SobBlock flags={flags} responses={responses} />
          <CoughBlock flags={flags} responses={responses} />
          <WheezeBlock flags={flags} responses={responses} />
          <ChestPainBlock flags={flags} responses={responses} />
          <OtherChestInjuriesSurgeriesBlock flags={flags} responses={responses} />
          <OtherLungProblemsBlock flags={flags} responses={responses} />
          <OtherLungSymptomsBlock flags={flags} responses={responses} />
          <HeartMurmurBlock flags={flags} responses={responses} />
          <HeartAttackBlock flags={flags} responses={responses} />
          <StrokeBlock flags={flags} responses={responses} />
          <AnginaBlock flags={flags} responses={responses} />
          <HeartFailureBlock flags={flags} responses={responses} />
          <ArrhythmiaBlock flags={flags} responses={responses} />
          <ImplantableCardiacDevicesBlock flags={flags} responses={responses} />
          <HypertensionBlock flags={flags} responses={responses} />
          <HeartburnBlock flags={flags} responses={responses} />
          <OtherHeartProblemsBlock flags={flags} responses={responses} />
          <OtherHeartSymptomsBlock flags={flags} responses={responses} />
          <MedicationsBlock flags={flags} responses={responses} />
          <VisionLossBlock flags={flags} responses={responses} />
          <VisionCorrectionBlock flags={flags} responses={responses} />
          <ColorBlindnessBlock flags={flags} responses={responses} />
          <OtherVisionProblemsBlock flags={flags} responses={responses} />
          <HearingLossBlock flags={flags} responses={responses} />
          <EarInjuriesBlock flags={flags} responses={responses} />
          <HearingAidsBlock flags={flags} responses={responses} />
          <OtherHearingProblemsBlock flags={flags} responses={responses} />
          <BackProblemsBlock flags={flags} responses={responses} />
          <WeaknessBlock flags={flags} responses={responses} />
          <RangeOfMotionBlock flags={flags} responses={responses} />
          <OtherMusculoskeletalProblemsBlock flags={flags} responses={responses} />
          <SpeakToProviderBlock flags={flags} responses={responses} />
        </div>
        <div className="mt-4 text-white text-xl font-bold">Review History</div>
        <div className="rounded-md mt-2 p-6 bg-gray-50 flex flex-col gap-8 items-stretch">
          {reviews === null ? (
            <div>Could not load.</div>
          ) : reviews.length === 0 ? (
            <div>No previous reviews.</div>
          ) : (
            <div className="divide-y-2 divide-gray-500">
              {reviews.map((review) => (
                <div key={review.id}>
                  <div><b>Time:</b> {format(review.creationTime, 'MMMM d yyyy h:mm:ss a')}</div>
                  <div><b>Provider:</b> {review.provider.id}</div>
                  <div><b>Decision:</b> {{
                    [RespiratorMedicalEvaluationStatus.APPROVED]: 'Approved',
                    [RespiratorMedicalEvaluationStatus.DEFERRED]: 'Deferred',
                    [RespiratorMedicalEvaluationStatus.EXPIRED_INTERVIEW]: 'Expired',
                    [RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW]: 'Interview Needed',
                    [RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED]: 'Partially Approved',
                    [RespiratorMedicalEvaluationStatus.PENDING_REVIEW]: 'Review Needed',
                  }[review.status]}</div>
                  <div><b>Internal Chart Comments:</b> {review.chartComments}</div>
                  <div><b>Public Comments:</b> {review.publicComments}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mt-4 text-white text-xl font-bold">Decision</div>
        <div className="rounded-md mt-2 p-2 bg-gray-50 flex flex-col gap-8 items-stretch">
          <div>
            <InlineRadioField
              onChange={setStatus}
              options={decisionOptions}
              value={status}
            />
          </div>
          <div className={status === RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW || status === RespiratorMedicalEvaluationStatus.DEFERRED ? 'opacity-50' : ''}>
            <StackedCheckboxField<RespiratorType>
              onChange={(val) => {
                if (status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED) {
                  setApprovedTypes(val);
                }
              }}
              options={respiratorMedicalEvaluation.respiratorTypeUsages.map((typeUsage) => ({
                label: (
                  <div className="flex flex-row gap-4">
                    <div><input checked={approvedTypes.includes(typeUsage.respiratorType)} readOnly type="checkbox" /></div>
                    <div>
                      <div className="font-bold">
                        {respiratorTypeLabels[typeUsage.respiratorType]}
                      </div>
                      <div className="ml-4 flex flex-row gap-x-2">
                        {typeUsage.usageScenarios.map((usageScenario, i) => (
                          <Fragment key={usageScenario}>
                            <span>{usageScenarioLabels[usageScenario]}</span>
                            {i !== typeUsage.usageScenarios.length - 1 && (
                              <span>&bull;</span>
                            )}
                          </Fragment>
                        ))}
                      </div>
                      <div className="ml-4 flex flex-row flex-wrap gap-x-2 text-sm text-gray-500">
                        {typeUsage.respiratoryHazards.map((respiratoryHazard, i) => (
                          <Fragment key={respiratoryHazard}>
                            <span>{respiratoryHazardLabels[respiratoryHazard]}</span>
                            {i !== typeUsage.respiratoryHazards.length - 1 && (
                              <span>&bull;</span>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                ),
                value: typeUsage.respiratorType,
              }))}
              value={approvedTypes}
            />
          </div>
          <div>
            <div className="text-md font-bold mb-2">Internal Chart Comments:</div>
            <TextAreaField
              maxLength={500}
              name="comments"
              onChange={setChartComments}
              value={chartComments}
            />
          </div>
          {(status === RespiratorMedicalEvaluationStatus.APPROVED
              || status === RespiratorMedicalEvaluationStatus.PARTIALLY_APPROVED
              || status === RespiratorMedicalEvaluationStatus.DEFERRED) && (
            <div>
              <div className="text-md font-bold mb-2">Public Comments (optional):</div>
              <div className="font-bold text-orange-500">
                <ExclamationTriangleIcon className="w-5 h-5 inline-block mr-2" />
                Do NOT include any PII or PHI/HIPAA data in this field!!
              </div>
              <TextAreaField
                maxLength={500}
                name="comments"
                onChange={setPublicComments}
                value={publicComments}
              />
            </div>
          )}
          {error && (
            <div className="rounded-md bg-red-100 text-red-500 p-4 border border-red-500">Error submitting.</div>
          )}
          <Button disabled={submitting || !isValid} loading={submitting} onClick={() => {
            void trySubmit();
          }}>
            Submit Decision
          </Button>
        </div>
      </div>
    </div>
  );
}

function getReviewableRme(chart: Chart | undefined): Chart['respiratorMedicalEvaluations'][number] | null {
  if (chart === undefined) {
    return null;
  }

  let respiratorMedicalEvaluation: Chart['respiratorMedicalEvaluations'][number] | null = null;

  for (const rme of chart.respiratorMedicalEvaluations) {
    if (rme.status === RespiratorMedicalEvaluationStatus.PENDING_REVIEW
        || rme.status === RespiratorMedicalEvaluationStatus.PENDING_INTERVIEW) {
      if (respiratorMedicalEvaluation !== null) {
        throw new Error('Multiple outstanding questionnaires.');
      }

      respiratorMedicalEvaluation = rme;
    }
  }

  return respiratorMedicalEvaluation;
}

export const ChartPage = withAuthenticator(ChartPageInner);
