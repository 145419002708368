import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HearingAidsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.hearingAids === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.hearingAids === YesNo.YES} title="Hearing Aids">
      <YesNoQuestion
        response={responses.hearingAids}
        text="Do you currently wear a hearing aid?"
      />
      <YesNoQuestion
        response={responses.hearingAidsNeedsForWork}
        text="Do you need a hearing aid to hear well enough for your job, to speak with coworkers, and to hear sounds that signal danger?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HEARING_AIDS_NOT_USABLE_AT_WORK)}
        response={responses.hearingAidsCanUseAtWork}
        text="Are you allowed to wear your hearing aid(s) at work?"
      />
    </QuestionSection>
  );
}
