import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { NyhaFunctionalClassification, YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HeartFailureBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.heartFailure === YesNo.YES || responses.peripheralEdema === YesNo.YES} title="Heart Failure">
      <YesNoQuestion
        highlight={responses.heartFailure === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE)}
        response={responses.heartFailure}
        text="Have you ever had heart failure?"
      />
      <YesNoQuestion
        response={responses.peripheralEdema}
        text="Have you ever had swelling in your legs or feet (not caused by walking)?"
      />
      <YesNoQuestion
        response={responses.peripheralEdemaCurrent}
        text="Do your legs or feet still get swollen (edema)?"
      />
      <EnumQuestion
        choices={{
          [NyhaFunctionalClassification.CLASS_I]: 'CLASS I (NO CHF SX)',
          [NyhaFunctionalClassification.CLASS_II]: 'CLASS II (MILD CHF SX)',
          [NyhaFunctionalClassification.CLASS_III]: 'CLASS III (MARKED CHF SX)',
          [NyhaFunctionalClassification.CLASS_IV]: 'CLASS IV (SEVERE CHF SX)',
        }}
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT) || flags.includes(RespiratorMedicalEvaluationFlag.MARKED_SX_OF_CHF)}
        response={responses.heartFailureFunctionalClassification}
        text="Which one of these options describes you best?"
      />
      {responses.heartAttack !== YesNo.YES && responses.anginaAssessedByDoctor !== YesNo.NO && responses.anginaCardiac !== YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_USE_OF_MEDS_FOR_ACUTE_ANGINA)}
          response={responses.anginaRescueMedUseLastYear}
          text="Have you had to take medicine for sudden chest pain, like nitroglycerin or beta blockers, in the last year?"
        />
      )}
      {responses.heartAttack !== YesNo.YES && responses.anginaAssessedByDoctor !== YesNo.NO && responses.anginaCardiac !== YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.ISCHEMIC_HEART_CONDITION_C_ADDITIONAL_SX)}
          response={responses.heartConditionAdditionalSxLast2Years}
          text="In the last two (2) years, have you ever felt chest pain, had trouble breathing, or felt really tired while working?"
        />
      )}
      {responses.heartAttack !== YesNo.YES && responses.anginaAssessedByDoctor !== YesNo.NO && responses.anginaCardiac !== YesNo.YES && (responses.heartFailure === YesNo.YES || responses.peripheralEdemaCurrent === YesNo.YES) && (
        <YesNoQuestion
          highlight={responses.heartCondition150MinutesActivityPerWeek === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartCondition150MinutesActivityPerWeek}
          text="Do you get at least 150 minutes of physical activity per week?"
        />
      )}
      {responses.heartAttack !== YesNo.YES && responses.anginaAssessedByDoctor !== YesNo.NO && responses.anginaCardiac !== YesNo.YES && (responses.heartFailure === YesNo.YES || responses.peripheralEdemaCurrent === YesNo.YES) && (
        <YesNoQuestion
          highlight={responses.heartConditionHighCholesterol === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartConditionHighCholesterol}
          text="Do you have high cholesterol?"
        />
      )}
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_CHF)}
        response={responses.heartFailureWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your heart failure?"
      />
      {responses.heartAttack !== YesNo.YES && responses.anginaCardiac !== YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CARDIAC_ISSUES_IN_RESPIRATOR)}
          response={responses.heartConditionRespiratorUseExpectedOk}
          text="Do you think you can use a respirator, or will it be too difficult because it makes breathing harder and you have a heart condition?"
        />
      )}
    </QuestionSection>
  );
}
