import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function SpeakToProviderBlock({
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.speakToProvider === YesNo.YES} title="Contact Us">
      <YesNoQuestion
        response={responses.speakToProvider}
        text="Would you like to talk to the health care professional who will review this questionnaire about your answers?"
      />
    </QuestionSection>
  );
}
