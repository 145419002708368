import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { TimeFrame, YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PneumothoraxBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.pneumothorax === YesNo.YES} title="Pneumothorax">
      <YesNoQuestion
        response={responses.pneumothorax}
        text="Have you ever had a pneumothorax (collapsed lung)?"
      />
      <EnumQuestion
        choices={{
          [TimeFrame.LESS_THAN_ONE_YEAR_AGO]: 'Less than 1 year ago',
          [TimeFrame.ONE_TO_THREE_YEARS_AGO]: '1 to 3 years ago',
          [TimeFrame.MORE_THAN_THREE_YEARS_AGO]: 'More than 3 years ago',
        }}
        highlight={((responses.pneumothoraxMostRecent === TimeFrame.LESS_THAN_ONE_YEAR_AGO || (responses.pneumothoraxMostRecent === TimeFrame.ONE_TO_THREE_YEARS_AGO && responses.pneumothoraxMultiple === YesNo.YES)) && (flags.includes(RespiratorMedicalEvaluationFlag.SMOKER_C_MEDIUM_RISK_PTX_HX) || flags.includes(RespiratorMedicalEvaluationFlag.MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT))) || flags.includes(RespiratorMedicalEvaluationFlag.RECENT_UNEXPLAINED_PTX) || flags.includes(RespiratorMedicalEvaluationFlag.RECENT_AND_MULTIPLE_PNEUMOTHORACES)}
        response={responses.pneumothoraxMostRecent}
        text="How long ago was your most recent pneumothorax?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_UNEXPLAINED_PTX)}
        response={responses.pneumothoraxKnowsCause}
        text="Do you know what caused the pneumothorax (for example, an injury or surgery)?"
      />
      <YesNoQuestion
        highlight={(responses.pneumothoraxMultiple === YesNo.YES && (flags.includes(RespiratorMedicalEvaluationFlag.SMOKER_C_MEDIUM_RISK_PTX_HX) || flags.includes(RespiratorMedicalEvaluationFlag.MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT)) || flags.includes(RespiratorMedicalEvaluationFlag.RECENT_AND_MULTIPLE_PNEUMOTHORACES))}
        response={responses.pneumothoraxMultiple}
        text="Have you had more than one pneumothorax?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.CURRENT_PTX_SX)}
        response={responses.pneumothoraxCurrentProblems}
        text="Do you still have any problems from your pneumothorax, such as chest pain or shortness of breath?"
      />
    </QuestionSection>
  );
}
