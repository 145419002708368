import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function RangeOfMotionBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.difficultyMovingExtremities === null && responses.painStiffnessBendingWaist === null && responses.difficultyMovingHeadVertically === null && responses.difficultyMovingHeadHorizontally === null && responses.difficultyBendingKnees === null && responses.difficultySquatting === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.difficultyMovingExtremities === YesNo.YES || responses.painStiffnessBendingWaist === YesNo.YES || responses.difficultyMovingHeadVertically === YesNo.YES || responses.difficultyMovingHeadHorizontally === YesNo.YES || responses.difficultyBendingKnees === YesNo.YES || responses.difficultySquatting === YesNo.YES} title="Range Of Motion">
      <YesNoQuestion
        response={responses.difficultyMovingExtremities}
        text="Do you currently have difficulty fully moving your arms and legs?"
      />
      <YesNoQuestion
        response={responses.painStiffnessBendingWaist}
        text="Do you currently have pain and stiffness when you lean forward or backward at the waist?"
      />
      <YesNoQuestion
        response={responses.difficultyMovingHeadVertically}
        text="Do you currently have difficulty fully moving your head up or down?"
      />
      <YesNoQuestion
        response={responses.difficultyMovingHeadHorizontally}
        text="Do you currently have difficulty fully moving your head side to side?"
      />
      <YesNoQuestion
        response={responses.difficultyBendingKnees}
        text="Do you currently have difficulty bending at your knees?"
      />
      <YesNoQuestion
        response={responses.difficultySquatting}
        text="Do you currently have difficulty squatting to the ground?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_DIFFICULTY_MOVING)}
        response={responses.difficultyMovingPredictsRespiratorWillExacerbate}
        text="Do you think the weight of a respirator would make any difficulties you have moving worse?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_DIFFICULTY_MOVING_SAR) || flags.includes(RespiratorMedicalEvaluationFlag.MODERATE_DIFFICULTY_MOVING_HEAVY_EXERTION)}
        response={responses.difficultyMovingSeverity}
        text="How bad is your trouble with moving?"
      />
    </QuestionSection>
  );
}
