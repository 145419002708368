import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PriorRespiratorUseFatigueBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.priorRespiratorUseFatigue === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.priorRespiratorUseFatigue === YesNo.YES} title="Fatigue">
      <YesNoQuestion
        response={responses.priorRespiratorUseFatigue}
        text="Has using a respirator ever caused you any general weakness or fatigue?"
      />
      <YesNoQuestion
        response={responses.priorRespiratorUseFatigueCurrent}
        text="Do you still feel weak or tired when you use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_DT_HEALTH_PROBLEM)}
        response={responses.priorRespiratorUseFatigueCausedByHealthProblem}
        text="Is the feeling of being weak or tired because of an illness or medical condition?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_NOT_DT_POOR_FITNESS)}
        response={responses.priorRespiratorUseFatigueCausedByPoorFitness}
        text="Do you feel weak or tired because you're not in good physical shape?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_IMPACTING_WORK)}
        response={responses.priorRespiratorUseFatigueImpactsWork}
        text="Does your weakness or fatigue make it hard for you to do your job or to keep up with your coworkers?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.FATIGUE_IN_RESPIRATOR_C_ADDITIONAL_SX)}
        response={responses.priorRespiratorUseFatigueAdditionalSx}
        text="When you felt weak or tired with a respirator on, did you also have chest pain, trouble breathing, or feel dizzy?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_FATIGUE_ISSUES_IN_RESPIRATOR)}
        response={responses.priorRespiratorUseFatigueFutureRespiratorUseExpectedNotOk}
        text="Do you think your weakness or fatigue will make it hard for you to use a respirator?"
      />
    </QuestionSection>
  );
}
