import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo, YesNoUnknown } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function AllergiesBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.allergies === YesNo.YES} title="Allergies">
      <YesNoQuestion
        response={responses.allergies}
        text="Have you ever had an allergic reaction that interfered with your breathing?"
      />
      <YesNoQuestion
        response={responses.allergiesAnaphylaxis}
        text="Have you ever had a really bad allergic reaction called anaphylaxis, or gone to the hospital because you had trouble breathing from an allergy?"
      />
      <YesNoQuestion
        highlight={responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK)}
        response={responses.allergiesMedications}
        text="Do you take any medicines for your allergies?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_ALLERGY_MEDS)}
        response={responses.allergiesMedicationsAdverseEffects}
        text="Do your allergy medicines make you feel sleepy, mixed up, tired, or not able to think clearly?"
      />
      <YesNoQuestion
        highlight={responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK)}
        response={responses.allergiesPrescribedEpipen}
        text="Have you been given a prescription for an EpiPen®?"
      />
      <YesNoQuestion
        response={responses.allergiesKnowsTriggers}
        text="Do you know what you are allergic to that causes your bad allergic reactions or breathing problems?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_ALLERGIC_REACTION_AT_WORK) || (responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK))}
        response={responses.allergiesHadAtWork}
        text="Have you ever had an allergic reaction at work?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EXPOSURE_TO_ALLERGENS_AT_WORK) || (responses.allergiesMedications === YesNo.NO && responses.allergiesPrescribedEpipen === YesNo.NO && (responses.allergiesHadAtWork === YesNo.NO || responses.allergiesTriggersAtWork === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK))}
        response={responses.allergiesTriggersAtWork}
        text="In your current job, will you work around the cause of your allergic reaction?"
      />
      <YesNoQuestion
        highlight={(responses.allergiesAtWorkEasilyTreatable === YesNoUnknown.NO || responses.allergiesAtWorkEasilyTreatable === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_UNTREATABLE_ALLERGIES_AT_WORK)}
        response={responses.allergiesAtWorkEasilyTreatable}
        text={`If you had an allergic reaction at work, could you easily get away and use your medicine${responses.allergiesPrescribedEpipen === YesNo.YES ? ', like your EpiPen®' : ''}?`}
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ALLERGY_ISSUES_IN_RESPIRATOR)}
        response={responses.allergiesMakesRespiratorUseUnsafe}
        text="Do you think your allergies will make it hard for you to use a respirator safely?"
      />
    </QuestionSection>
  );
}
