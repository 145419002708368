import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function LungCancerBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.lungCancer === YesNo.YES} title="Lung Cancer">
      <YesNoQuestion
        response={responses.lungCancer}
        text="Have you ever had lung cancer?"
      />
      <YesNoQuestion
        highlight={responses.lungCancerInRemission === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.LUNG_CANCER_NOT_IN_REMISSION_FIVE_YEARS)}
        response={responses.lungCancerInRemission}
        text="Are you currently in remission?"
      />
      <YesNoQuestion
        highlight={responses.lungCancerInRemissionAtLeastFiveYears === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.LUNG_CANCER_NOT_IN_REMISSION_FIVE_YEARS)}
        response={responses.lungCancerInRemissionAtLeastFiveYears}
        text="Have you been in remission for five (5) years or more?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.NO_PROVEN_SAR_USE_SINCE_LUNG_CANCER_REMISSION)}
        response={responses.lungCancerUsedSarAtWorkSinceRemission}
        text="Have you used a supplied-air respirator (SAR) or an SCBA at work since your remission?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_LUNG_CANCER_ISSUES_IN_RESPIRATOR_SINCE_REMISSION)}
        response={responses.lungCancerProblemsWithSar}
        text="Did you have any problems, especially with breathing, when you used the supplied-air respirator (SAR) or SCBA?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_LUNG_CANCER)}
        response={responses.lungCancerWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations because of your lung cancer history?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_LUNG_CANCER_ISSUES_IN_RESPIRATOR)}
        response={responses.lungCancerCurrentProblemsMakeRespiratorUseDifficult}
        text="Do you have any lasting lung problems that might make it hard for you to use a respirator?"
      />
    </QuestionSection>
  );
}
