import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function AnginaBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.angina === YesNo.YES || responses.chestPainFrequent === YesNo.YES || responses.chestPainDuringPhysicalActivity === YesNo.YES || responses.chestPainInterferingWithWork === YesNo.YES} title="Angina">
      <YesNoQuestion
        response={responses.angina}
        text="Have you ever had angina (chest pain caused by your heart)?"
      />
      <YesNoQuestion
        response={responses.chestPainFrequent}
        text="Have you ever had frequent pain or tightness in your chest?"
      />
      <YesNoQuestion
        response={responses.chestPainDuringPhysicalActivity}
        text="Have you ever had pain or tightness in your chest during physical activity?"
      />
      <YesNoQuestion
        response={responses.chestPainInterferingWithWork}
        text="Have you ever had pain or tightness in your chest that interferes with your job?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNASSESSED_ANGINA)}
        response={responses.anginaAssessedByDoctor}
        text="Has your chest pain been checked by a doctor?"
      />
      <EnumQuestion
        choices={{
          [YesNo.YES]: 'HEART-RELATED',
          [YesNo.NO]: 'NOT HEART-RELATED',
        }}
        highlight={responses.anginaCardiac === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE)}
        response={responses.anginaCardiac}
        text="What did your doctor say was the cause of your chest pain?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_ANGINA)}
        response={responses.anginaLastYear}
        text="Have you had chest pain within the last twelve (12) months?"
      />
      {responses.heartAttack !== YesNo.YES && (responses.anginaAssessedByDoctor === YesNo.NO || responses.anginaCardiac === YesNo.YES) && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_USE_OF_MEDS_FOR_ACUTE_ANGINA)}
          response={responses.anginaRescueMedUseLastYear}
          text="Have you had to take medicine for sudden chest pain, like nitroglycerin or beta blockers, in the last year?"
        />
      )}
      {responses.heartAttack !== YesNo.YES && (responses.anginaAssessedByDoctor === YesNo.NO || responses.anginaCardiac === YesNo.YES) && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.ISCHEMIC_HEART_CONDITION_C_ADDITIONAL_SX)}
          response={responses.heartConditionAdditionalSxLast2Years}
          text="In the last two (2) years, have you ever felt chest pain, had trouble breathing, or felt really tired while working?"
        />
      )}
      {responses.heartAttack !== YesNo.YES && (responses.anginaAssessedByDoctor === YesNo.NO || responses.anginaCardiac === YesNo.YES) && (
        <YesNoQuestion
          highlight={responses.heartCondition150MinutesActivityPerWeek === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartCondition150MinutesActivityPerWeek}
          text="Do you get at least 150 minutes of physical activity per week?"
        />
      )}
      {responses.heartAttack !== YesNo.YES && (responses.anginaAssessedByDoctor === YesNo.NO || responses.anginaCardiac === YesNo.YES) && (
        <YesNoQuestion
          highlight={responses.heartConditionHighCholesterol === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
          response={responses.heartConditionHighCholesterol}
          text="Do you have high cholesterol?"
        />
      )}
      <YesNoQuestion
        highlight={responses.anginaRestingOrDuringLightActivity === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_ANGINA)}
        response={responses.anginaRestingOrDuringLightActivity}
        text="Do you ever get chest pain when you're resting or doing light activity?"
      />
      <YesNoQuestion
        highlight={responses.anginaPredictable === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_ANGINA)}
        response={responses.anginaPredictable}
        text="Is your chest pain predictable, for example only happening when you do some physical activity or exercise?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNCONTROLLED_ANGINA)}
        response={responses.anginaControlled}
        text="Do both you and your doctor think your chest pain is under control and well-managed?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ANGINA)}
        response={responses.anginaWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your chest pain?"
      />
      {responses.heartAttack !== YesNo.YES && responses.anginaCardiac === YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CARDIAC_ISSUES_IN_RESPIRATOR)}
          response={responses.heartConditionRespiratorUseExpectedOk}
          text="Do you think you can use a respirator, or will it be too difficult because it makes breathing harder and you have a heart condition?"
        />
      )}
    </QuestionSection>
  );
}
