import { Fragment } from 'react';

import { PhysicalExertionLevel, RespiratorType, RespiratorUsageScenario, RespiratoryHazard, WorkEnvironment } from '../lib/general';
import { JobRole, RespiratorMedicalEvaluationRespiratorTypeUsage } from '../types/general';
import { RespiratorMedicalEvaluationFlag } from '../lib/flags';

const respiratorTypeLabels = {
  [RespiratorType.AIR_PURIFYING_DISPOSABLE]: 'Disposable Respirator (N95, P100, etc.)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_FULL_FACE]: 'Full-Face Air Purifying (APR)',
  [RespiratorType.AIR_PURIFYING_REUSABLE_PARTIAL_FACE]: 'Partial-Face Air Purifying (APR)',
  [RespiratorType.POWERED_AIR_PURIFYING_FULL_FACE]: 'Full-Face Powered Air Purifying (PAPR)',
  [RespiratorType.POWERED_AIR_PURIFYING_PARTIAL_FACE]: 'Partial-Face Powered Air Purifying (PAPR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE]: 'Full-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE]: 'Partial-Face Supplied Air (SAR)',
  [RespiratorType.SUPPLIED_AIR_SCBA]: 'Self-Contained Breathing Apparatus (SCBA)',
};

const usageScenarioLabels = {
  [RespiratorUsageScenario.ESCAPE]: 'Emergency Escape',
  [RespiratorUsageScenario.FIREFIGHTING]: 'Firefighting',
  [RespiratorUsageScenario.RESCUE]: 'Rescue Team (Rescue Role)',
  [RespiratorUsageScenario.RESCUE_OVERSIGHT]: 'Rescue Team (Oversight Role)',
  [RespiratorUsageScenario.ROUTINE]: 'Routine Use',
  [RespiratorUsageScenario.SITUATIONAL]: 'Situational Use',
};

const respiratoryHazardLabels = {
  [RespiratoryHazard.CHEMICALS]: 'Chemicals',
  [RespiratoryHazard.DUST]: 'Dust',
  [RespiratoryHazard.FUMES]: 'Fumes',
  [RespiratoryHazard.INFECTIOUS_AGENTS]: 'Infectious Agents',
  [RespiratoryHazard.OXYGEN_DEFICIENT]: 'Oxygen-Deficient',
  [RespiratoryHazard.OXYGEN_ENRICHED]: 'Oxygen-Enriched',
  [RespiratoryHazard.SENSITIZERS]: 'Sensitizers',
  [RespiratoryHazard.SMOKE]: 'Smoke',
  [RespiratoryHazard.VAPORS]: 'Vapors',
};

const physicalExertionLabels = {
  [PhysicalExertionLevel.LIGHT]: 'Light',
  [PhysicalExertionLevel.MODERATE]: 'Moderate',
  [PhysicalExertionLevel.HEAVY]: 'Heavy',
};

const workEnvironmentLabels = {
  [WorkEnvironment.CONFINED_SPACE]: 'Confined Space',
  [WorkEnvironment.EXTREME_COLD]: 'Extreme Cold',
  [WorkEnvironment.EXTREME_HEAT]: 'Extreme Heat',
  [WorkEnvironment.EXTREME_HEIGHT]: 'Extreme Height',
  [WorkEnvironment.EXTREME_HUMIDITY]: 'Extreme Humidity',
};

export function JobRoleSummary({
  flags,
  jobRole,
  respiratorTypeUsages,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  jobRole: JobRole;
  respiratorTypeUsages: RespiratorMedicalEvaluationRespiratorTypeUsage[];
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-x-6 gap-y-4">
        <div>
          <h3 className="text-lg font-bold">Job Role</h3>
          {' '}
          {jobRole.name}
        </div>
      </div>
      <div className="flex flex-col gap-4 border-t-2 border-gray-100 pt-4">
        {respiratorTypeUsages.map((typeUsage) => (
          <div key={typeUsage.respiratorType}>
            <div>
              <span className={`
                font-bold
                ${
                  (
                    typeUsage.respiratorType === RespiratorType.SUPPLIED_AIR_HOSE_FULL_FACE
                    || typeUsage.respiratorType === RespiratorType.SUPPLIED_AIR_HOSE_PARTIAL_FACE
                    || typeUsage.respiratorType === RespiratorType.SUPPLIED_AIR_SCBA
                  ) && (
                    flags.includes(RespiratorMedicalEvaluationFlag.RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.NO_PROVEN_SAR_USE_SINCE_LUNG_CANCER_REMISSION)
                    || flags.includes(RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.HX_CVA_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.HTN_MODERATE_EXERTION_SAR)
                    || flags.includes(RespiratorMedicalEvaluationFlag.UNRESOLVED_BACK_INJURY_SAR)
                    || flags.includes(RespiratorMedicalEvaluationFlag.DIFFICULTY_CARRYING_WEIGHT_SAR)
                    || flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_DIFFICULTY_MOVING_SAR)
                  )
                    ? 'bg-orange-200'
                    : ''
                }
              `}>
                {respiratorTypeLabels[typeUsage.respiratorType]}
              </span>
            </div>
            <div className="ml-4 flex flex-row gap-x-2">
              {typeUsage.usageScenarios.map((usageScenario, i) => (
                <Fragment key={usageScenario}>
                  <span>{usageScenarioLabels[usageScenario]}</span>
                  {i !== typeUsage.usageScenarios.length - 1 && (
                    <span>&bull;</span>
                  )}
                </Fragment>
              ))}
            </div>
            <div className="ml-4 flex flex-row flex-wrap gap-x-2 text-sm text-gray-500">
              {typeUsage.respiratoryHazards.map((respiratoryHazard, i) => (
                <Fragment key={respiratoryHazard}>
                  <span className={`
                    ${
                      ((respiratoryHazard === RespiratoryHazard.OXYGEN_DEFICIENT
                        || respiratoryHazard === RespiratoryHazard.OXYGEN_ENRICHED
                      ) && (
                        flags.includes(RespiratorMedicalEvaluationFlag.RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.HX_CVA_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT)
                      ))
                        ? 'bg-orange-200'
                        : ''
                    }
                  `}>
                    {respiratoryHazardLabels[respiratoryHazard]}
                  </span>
                  {i !== typeUsage.respiratoryHazards.length - 1 && (
                    <span>&bull;</span>
                  )}
                </Fragment>
              ))}
            </div>
            <div className="ml-4 flex flex-row flex-wrap gap-x-2 text-sm text-gray-500">
              {typeUsage.workEnvironments.map((workEnvironment, i) => (
                <Fragment key={workEnvironment}>
                  <span className={`
                    ${
                      (workEnvironment === WorkEnvironment.CONFINED_SPACE && (
                        flags.includes(RespiratorMedicalEvaluationFlag.WORK_IN_CONFINED_SPACES_C_CLAUSTROPHOBIA)
                        || flags.includes(RespiratorMedicalEvaluationFlag.RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.HX_CVA_HIGH_RISK_ENVIRONMENT)
                        || flags.includes(RespiratorMedicalEvaluationFlag.MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT)
                      ))
                        ? 'bg-orange-200'
                        : ''
                    }
                  `}>
                    {workEnvironmentLabels[workEnvironment]}
                  </span>
                  {i !== typeUsage.workEnvironments.length - 1 && (
                    <span>&bull;</span>
                  )}
                </Fragment>
              ))}
            </div>
            <div className="ml-4 flex flex-row flex-wrap gap-x-2 text-sm text-gray-500">
              <span className={`
                ${
                  (typeUsage.idlhEnvironment && (
                    flags.includes(RespiratorMedicalEvaluationFlag.RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.HYPOSMIA_SMELL_CRITICAL_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.MEDIUM_RISK_PTX_HX_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.HX_CVA_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.MILD_SX_OF_CHF_HIGH_RISK_ENVIRONMENT)
                    || flags.includes(RespiratorMedicalEvaluationFlag.IMPLANTABLE_CARDIAC_DEVICE_IDLH)
                  ))
                    ? 'bg-orange-200'
                    : ''
                }
              `}>
                IDLH: {typeUsage.idlhEnvironment ? 'Yes' : 'No'}
              </span>
            </div>
            <div className="ml-4 flex flex-row flex-wrap gap-x-2 text-sm text-gray-500">
              <span className={`
                ${
                  (
                    (
                      (
                        typeUsage.physicalExertionLevel === PhysicalExertionLevel.MODERATE
                        || typeUsage.physicalExertionLevel === PhysicalExertionLevel.HEAVY
                      )
                      && (
                        flags.includes(RespiratorMedicalEvaluationFlag.HX_CVA_MODERATE_EXERTION)
                        || flags.includes(RespiratorMedicalEvaluationFlag.HTN_MODERATE_EXERTION_SAR)
                      )
                    )
                    || (
                      (
                        typeUsage.physicalExertionLevel === PhysicalExertionLevel.HEAVY
                      )
                      && (
                        flags.includes(RespiratorMedicalEvaluationFlag.MODERATE_DIFFICULTY_MOVING_HEAVY_EXERTION)
                      )
                    )
                  )
                    ? 'bg-orange-200'
                    : ''
                }
              `}>
                Physical Exertion: {physicalExertionLabels[typeUsage.physicalExertionLevel]}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
