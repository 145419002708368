import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo, YesNoUnknown } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HeartburnBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.heartburn === YesNo.YES} title="Heartburn">
      <YesNoQuestion
        response={responses.heartburn}
        text="Have you ever had heartburn or indigestion that is not related to eating?"
      />
      <YesNoQuestion
        response={responses.heartburnRelievedByAntacids}
        text="Does your heartburn feel better when you take antacids (like Tums®) or heartburn medicines like proton pump inhibitors (PPIs, for example omeprazole)?"
      />
      <EnumQuestion
        choices={{
          [YesNoUnknown.YES]: 'HEART-RELATED',
          [YesNoUnknown.NO]: 'NOT HEART-RELATED',
          [YesNoUnknown.UNKNOWN]: 'HASN\'T BEEN CHECKED',
        }}
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.CARDIAC_HEARTBURN) || flags.includes(RespiratorMedicalEvaluationFlag.HEARTBURN_S_DX)}
        response={responses.heartburnCardiac}
        text="What does your doctor say is the cause of your heartburn?"
      />
    </QuestionSection>
  );
}
