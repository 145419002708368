import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function TuberculosisBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.tuberculosis === YesNo.YES} title="Tuberculosis">
      <YesNoQuestion
        response={responses.tuberculosis}
        text="Have you ever had tuberculosis?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNRESOLVED_TB)}
        response={responses.tuberculosisResolved}
        text="Is your tuberculosis resolved and are you feeling OK without any symptoms?"
      />
      <YesNoQuestion
        response={responses.tuberculosisCurrentMedications}
        text="Are you still taking any medicines for your tuberculosis?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_ACTIVE_TB)}
        response={responses.tuberculosisCurrentMedicationsForLatentTbOnly}
        text="Is the medicine you're taking only for inactive tuberculosis (latent TB)?"
      />
    </QuestionSection>
  );
}
