import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Homepage } from './pages/Homepage';
import { ChartPage } from './pages/ChartPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/charts/:id',
    element: <ChartPage />,
  },
]);

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_COGNITO_DOMAIN,
          scopes: [
            'email',
            'openid',
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code',
        },
      },
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
    },
  },
});

const authenticatorComponents = {
  SignIn: {
    Footer() {
      return null;
    },
  },
};

export function App() {
  return (
    <Authenticator components={authenticatorComponents} hideSignUp>
      <RouterProvider router={router} />
    </Authenticator>
  );
}
