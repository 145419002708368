import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function WheezeBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.wheeze === YesNo.YES || responses.wheezeInterferingWithWork === YesNo.YES} title="Wheezing">
      <YesNoQuestion
        response={responses.wheeze}
        text="Do you currently experience wheezing?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WHEEZING_IMPACTING_WORK)}
        response={responses.wheezeInterferingWithWork}
        text="Do you currently experience wheezing that interferes with your job?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_WHEEZING)}
        response={responses.wheezeWorseLastYear}
        text="Has your wheezing gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_WHEEZING)}
        response={responses.wheezeSeverity}
        text="How bad do you and your doctor think your wheezing is?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_WHEEZING_ISSUES_IN_RESPIRATOR)}
        response={responses.wheezePriorRespiratorUseNotOk}
        text="Has your wheezing ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WHEEZING_ISSUES_IN_RESPIRATOR)}
        response={responses.wheezeFutureRespiratorUseExpectedNotOk}
        text="Do you think your wheezing will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_WHEEZING)}
        response={responses.wheezeWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your wheezing?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WHEEZING_AGGRAVATORS_AT_WORK)}
        response={responses.wheezeAggravatorsAtWork}
        text="Will you be working with anything at your job that triggers your wheezing or makes your wheezing worse?"
      />
      <YesNoQuestion
        response={responses.wheezeMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WHEEZING_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.wheezeMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_WHEEZING)}
        response={responses.wheezeHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your wheezing?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_WHEEZING_IMPACTING_WORK)}
        response={responses.wheezeImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your wheezing?"
      />
    </QuestionSection>
  );
}
