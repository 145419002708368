import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function VisionLossBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.visionLoss === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.visionLoss === YesNo.YES} title="Vision Loss">
      <YesNoQuestion
        response={responses.visionLoss}
        text="Have you ever lost vision in either eye (temporarily or permanently)?"
      />
      <YesNoQuestion
        response={responses.visionLossResolved}
        text="Has your vision returned?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.VISION_LOSS_IMPACTING_WORK)}
        response={responses.visionLossCanPerformWorkSafely}
        text="Can you see well enough to safely do your job and notice any visual dangers like spills, warning signs, or steps?"
      />
      <YesNoQuestion
        response={responses.visionLossPriorScbaOrFullFaceUse}
        text="Have you used an SCBA or full-face respirator before?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR)}
        response={responses.visionLossPriorScbaOrFullFaceUseSuccessful}
        text="Could you see well enough with the SCBA or full-face respirator to do your job safely?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_VISION_ISSUES_IN_FULL_FACE_RESPIRATOR)}
        response={responses.visionLossExpectsSuccessfulScbaOrFullFaceUse}
        text="When you eventually use an SCBA or full-face respirator, do you think you can see well enough to do your job safely?"
      />
    </QuestionSection>
  );
}
