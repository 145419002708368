import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function OtherChestInjuriesSurgeriesBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.miscChestInjuriesOrSurgeries === YesNo.YES} title="Other Chest Injuries & Surgeries">
      <YesNoQuestion
        response={responses.miscChestInjuriesOrSurgeries}
        text="Have you ever had any other chest injuries or surgeries that were not covered in the questions above?"
      />
      <YesNoQuestion
        response={responses.miscChestInjuriesOrSurgeriesResolved}
        text="Are you completely better now from your chest injuries or surgeries?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNRESOLVED_CHEST_INJURY_OR_SURGERY_C_DYSPNEA)}
        response={responses.miscChestInjuriesOrSurgeriesCanExerciseWithoutSob}
        text="Can you do moderately intense exercise without getting out of breath?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_CHEST_INJURY_OR_SURGERY)}
        response={responses.miscChestInjuriesOrSurgeriesCanPerformWorkFully}
        text="Do you think you can do your job without any problems or limitations caused by your chest injuries or surgeries?"
      />
    </QuestionSection>
  );
}
