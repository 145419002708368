import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function BackProblemsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.backInjury === null && responses.backPain === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.backInjury === YesNo.YES || responses.backPain === YesNo.YES} title="Back Problems">
      <YesNoQuestion
        response={responses.backInjury}
        text="Have you ever had a back injury?"
      />
      <YesNoQuestion
        response={responses.backPain}
        text="Do you have back pain now or do you get it often?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNRESOLVED_BACK_INJURY_SAR)}
        response={responses.backInjuryResolved}
        text="Are you totally better from your back injury, or do you still occasionally have some pain or trouble doing things?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_BACK_PAIN)}
        response={responses.backPainPredictsRespiratorWillExacerbate}
        text="Do you think the weight of a respirator would make your back pain worse?"
      />
    </QuestionSection>
  );
}
