import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo, YesNoUnknown } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function HeartMurmurBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.heartMurmur === YesNo.YES} title="Heart Murmur">
      <YesNoQuestion
        response={responses.heartMurmur}
        text="Do you have a heart murmur?"
      />
      <YesNoQuestion
        highlight={responses.heartMurmurCheckedByDoctor === YesNo.NO && flags.includes(RespiratorMedicalEvaluationFlag.HEART_MURMUR_S_DX)}
        response={responses.heartMurmurCheckedByDoctor}
        text="Has a doctor checked your heart murmur?"
      />
      <YesNoQuestion
        highlight={(responses.heartMurmurClearedByDoctor === YesNoUnknown.UNKNOWN && flags.includes(RespiratorMedicalEvaluationFlag.HEART_MURMUR_S_DX) || flags.includes(RespiratorMedicalEvaluationFlag.SERIOUS_HEART_MURMUR))}
        response={responses.heartMurmurClearedByDoctor}
        text="Did the doctor tell you your heart murmur was harmless (innocent)?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HEART_MURMUR_C_ADDITIONAL_CARDIAC_SX)}
        response={responses.heartMurmurAdditionalSx}
        text="Does your heart murmur cause any other heart symptoms, like chest pain, heart fluttering, trouble breathing, or feeling like your heart misses beats?"
      />
    </QuestionSection>
  );
}
