import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function EarInjuriesBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.brokenEardrum === null && responses.miscEarInjuries === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.brokenEardrum === YesNo.YES || responses.miscEarInjuries === YesNo.YES} title="Ear Injuries">
      <YesNoQuestion
        response={responses.brokenEardrum}
        text="Have you ever had a broken eardrum?"
      />
      <YesNoQuestion
        response={responses.brokenEardrumResolved}
        text="Has your broken eardrum fully healed or been surgically repaired?"
      />
      <YesNoQuestion
        response={responses.miscEarInjuries}
        text="Have you ever had any injury to your ears other than a broken eardrum?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.LASTING_COMPLICATIONS_FROM_EAR_INJURY)}
        response={responses.miscEarInjuriesLastingComplications}
        text="Do you have any lasting complications from your ear injury?"
      />
      <DescriptionQuestion
        response={responses.miscEarInjuriesLastingComplicationsDescription}
        text="Please provide more details about the lasting complications from your ear injury:"
      />
    </QuestionSection>
  );
}
