import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function OtherMusculoskeletalProblemsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.miscMusculoskeletalProblems === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.miscMusculoskeletalProblems === YesNo.YES} title="Other Musculoskeletal Problems">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_MUSCULOSKELETAL_PROBLEM)}
        response={responses.miscMusculoskeletalProblems}
        text="Do you currently have any other muscle or skeletal problem that would interfere with using a respirator?"
      />
      <DescriptionQuestion
        response={responses.miscMusculoskeletalProblemsDescription}
        text="Please provide more details about your muscle or skeletal problem(s):"
      />
    </QuestionSection>
  );
}
