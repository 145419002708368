import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function AsthmaBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.asthma === YesNo.YES} title="Asthma">
      <YesNoQuestion
        response={responses.asthma}
        text="Have you ever had asthma?"
      />
      <YesNoQuestion
        response={responses.asthmaCurrent}
        text="Do you still have asthma?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ASTHMA_SX_DURING_ADL)}
        response={responses.asthmaSxDuringAdl}
        text="Does your asthma bother you when you do everyday things like chores, exercising, or walking up stairs?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_ASTHMA)}
        response={responses.asthmaWorseLastYear}
        text="Has your asthma gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_ASTHMA)}
        response={responses.asthmaSeverity}
        text="How bad do you and your doctor think your asthma is?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_ASTHMA_ISSUES_IN_RESPIRATOR)}
        response={responses.asthmaPriorRespiratorUseNotOk}
        text="Has your asthma ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ASTHMA_ISSUES_IN_RESPIRATOR)}
        response={responses.asthmaFutureRespiratorUseExpectedNotOk}
        text="Do you think your asthma will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ASTHMA)}
        response={responses.asthmaWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your asthma?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ASTHMA_AGGRAVATORS_AT_WORK)}
        response={responses.asthmaAggravatorsAtWork}
        text="Will you be working with anything at your job that triggers your asthma or makes your asthma worse?"
      />
      <YesNoQuestion
        response={responses.asthmaMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      {responses.asthmaMedications === YesNo.YES && (
        <YesNoQuestion
          highlight={flags.includes(RespiratorMedicalEvaluationFlag.POORLY_CONTROLLED_ASTHMA)}
          response={responses.rescueInhalerUseAtLeast3PerWeek}
          text="Do you use your rescue inhaler three or more times per week?"
        />
      )}
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ASTHMA_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.asthmaMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_ASTHMA)}
        response={responses.asthmaHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your asthma?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_ASTHMA_IMPACTING_WORK)}
        response={responses.asthmaImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your asthma?"
      />
    </QuestionSection>
  );
}
