import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function WeaknessBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.weaknessInExtremities === null && responses.difficultyClimbingStairs25Pounds === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.weaknessInExtremities === YesNo.YES || responses.difficultyClimbingStairs25Pounds === YesNo.YES} title="Weakness">
      <YesNoQuestion
        response={responses.weaknessInExtremities}
        text="Do you currently have weakness in any of your arms, hands, legs, or feet?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.DIFFICULTY_CARRYING_WEIGHT_SAR)}
        response={responses.difficultyClimbingStairs25Pounds}
        text="Would you have difficulty climbing a flight of stairs or a ladder carrying more than 25 pounds?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_RESPIRATOR_WILL_EXACERBATE_WEAKNESS)}
        response={responses.weaknessPredictsRespiratorWillExacerbate}
        text="Do you think the weight of a respirator would make your weakness worse?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WEAKNESS_IMPACTING_WORK)}
        response={responses.weaknessImpactingWork}
        text="Does your weakness make it hard for you to do your job or to keep up with your coworkers?"
      />
      <EnumQuestion
        choices={{
          [YesNo.YES]: 'GETTING WORSE',
          [YesNo.NO]: 'STAYED THE SAME',
        }}
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_WEAKNESS)}
        response={responses.weaknessWorsening}
        text="Is your weakness getting worse over time, or has it stayed the same?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_WEAKNESS)}
        response={responses.weaknessWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your weakness?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WEAKNESS_IMPACTING_SAFE_REMOVAL)}
        response={responses.weaknessCanEscapeDanger}
        text="If there's an emergency, would you be able to get out of a dangerous situation safely and quickly?"
      />
      <YesNoQuestion
        response={responses.weaknessUsedSarLast2Years}
        text="Have you used a supplied-air respirator (SAR) or an SCBA in the last two (2) years?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_WEAKNESS_ISSUES_IN_SAR)}
        response={responses.weaknessPriorSarUseOkLast2Years}
        text="Were you able to wear the supplied-air respirator (SAR) or SCBA and do your job with it on without any problems?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_SAR_ISSUES_DT_WEAKNESS)}
        response={responses.weaknessMakingCarryingEquipmentDifficult}
        text="Will your weakness make it hard to wear protective gear and carry up to 25 pounds of respirator equipment on your back while working?"
      />
    </QuestionSection>
  );
}
