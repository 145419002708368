import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function OtherLungSymptomsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.miscLungSymptoms === YesNo.YES} title="Other Lung Symptoms">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_LUNG_SX)}
        response={responses.miscLungSymptoms}
        text="Do you currently have any other symptoms not covered in the questions above that may be related to a lung problem?"
      />
      <DescriptionQuestion
        response={responses.miscLungSymptomsDescription}
        text="Please provide more details about your lung symptoms:"
      />
    </QuestionSection>
  );
}
