import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { PriorRespiratorUseProblemQuestion } from './PriorRespiratorUseProblemQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PriorRespiratorUseSkinProblemsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.priorRespiratorUseSkinProblems === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.priorRespiratorUseSkinProblems === YesNo.YES} title="Skin Problems">
      <YesNoQuestion
        response={responses.priorRespiratorUseSkinProblems}
        text="Has using a respirator ever caused you skin rashes or allergies?"
      />
      <YesNoQuestion
        response={responses.priorRespiratorUseSkinProblemsCurrent}
        text="Do you still get a rash when you use a respirator?"
      />
      <PriorRespiratorUseProblemQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK)}
        response={responses.priorRespiratorUseSkinProblemsCause}
        text="What was the cause of your rash or allergy?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.DERMAL_PROBLEM_DT_RESPIRATOR_S_ALTERNATIVE)}
        response={responses.priorRespiratorUseSkinProblemsAbleToUseAlternateRespirator}
        text="In your current job, are you able to use a style of respirator that does not cause you any skin problems?"
      />
      <YesNoQuestion
        response={responses.priorRespiratorUseSkinProblemsAggravatorsAtWork}
        text="Will you still be working around the thing that causes your rash or allergy?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_DERMAL_PROBLEM_AGGRAVATORS_AT_WORK_PPE_UNAVAILABLE)}
        response={responses.priorRespiratorUseSkinProblemsCanUsePpe}
        text="At your job, are you able to prevent your skin rashes by wearing protective gear like gloves or coveralls?"
      />
    </QuestionSection>
  );
}
