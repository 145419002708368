import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { TimeFrame, YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function SeizuresBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.seizures === YesNo.YES || responses.seizuresMedications === YesNo.YES} title="Seizures">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEIZURE_MEDS_S_DX)}
        response={responses.seizures}
        text="Have you ever had a seizure?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEIZURE_MEDS_S_DX)}
        response={responses.seizuresMedications}
        text="Are you currently taking any medications for seizures?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_SEIZURE_MEDS)}
        response={responses.seizuresMedicationsAdverseEffects}
        text="Do your seizure medicines make you feel sleepy, mixed up, tired, or not able to think, or stop you from doing your job safely?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_SEIZURES)}
        response={responses.seizuresWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your seizures?"
      />
      <EnumQuestion
        choices={{
          [TimeFrame.LESS_THAN_FIVE_YEARS_AGO]: 'Less than 5 years ago',
          [TimeFrame.FIVE_TO_TEN_YEARS_AGO]: '5 to 10 years ago',
          [TimeFrame.MORE_THAN_TEN_YEARS_AGO]: 'More than 10 years ago',
        }}
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_SEIZURE_HIGH_RISK_ENVIRONMENT) || flags.includes(RespiratorMedicalEvaluationFlag.RECENT_AND_MULTIPLE_SEIZURES)}
        response={responses.seizuresMostRecent}
        text="How long ago was your last seizure?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_AND_MULTIPLE_SEIZURES)}
        response={responses.seizuresMultiple}
        text="Have you had more than one (1) seizure in your life?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_SEIZURES_AT_WORK)}
        response={responses.seizuresHadAtWork}
        text="Have you ever had a seizure at work?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_SEIZURES_S_SAFE_REMOVAL)}
        response={responses.seizuresWarningSigns}
        text="Do you get any warnings or signs before a seizure that let you know it's coming?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ALONE_C_SEIZURES)}
        response={responses.seizuresWorksAlone}
        text="Will you be working by yourself (meaning, if you had a seizure, no one would be there to help)?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNCONTROLLED_SEIZURES)}
        response={responses.seizuresControlled}
        text="Do you and your doctors feel your seizures are controlled?"
      />
    </QuestionSection>
  );
}
