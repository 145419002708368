import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function SilicosisBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.silicosis === YesNo.YES} title="Silicosis">
      <YesNoQuestion
        response={responses.silicosis}
        text="Have you ever had silicosis (the lung disease caused by silica)?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SILICOSIS_SX_DURING_ADL)}
        response={responses.silicosisSxDuringAdl}
        text="Does your silicosis bother you when you do everyday things like chores, exercising, or walking up stairs?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_SILICOSIS)}
        response={responses.silicosisWorseLastYear}
        text="Has your silicosis gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_SILICOSIS)}
        response={responses.silicosisSeverity}
        text="How bad do you and your doctor think your silicosis is?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_SILICOSIS_ISSUES_IN_RESPIRATOR)}
        response={responses.silicosisPriorRespiratorUseNotOk}
        text="Has your silicosis ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_SILICOSIS_ISSUES_IN_RESPIRATOR)}
        response={responses.silicosisFutureRespiratorUseExpectedNotOk}
        text="Do you think your silicosis will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_SILICOSIS)}
        response={responses.silicosisWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your silicosis?"
      />
      <YesNoQuestion
        response={responses.silicosisMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SILICOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.silicosisMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_SILICOSIS)}
        response={responses.silicosisHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your silicosis?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_SILICOSIS_IMPACTING_WORK)}
        response={responses.silicosisImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your silicosis?"
      />
    </QuestionSection>
  );
}
