import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function OtherHeartProblemsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.miscHeartProblems === YesNo.YES} title="Other Heart Problems">
      <YesNoQuestion
        highlight={responses.miscHeartProblems === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_HEART_CONDITION)}
        response={responses.miscHeartProblems}
        text="Have you ever had any other heart problems that you've been told about that were not covered in the questions above?"
      />
      <DescriptionQuestion
        response={responses.miscHeartProblemsDescription}
        text="Please provide more details about your heart problem(s):"
      />
    </QuestionSection>
  );
}
