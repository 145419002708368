import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function OtherVisionProblemsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.miscVisionProblems === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.miscVisionProblems === YesNo.YES} title="Other Vision Problems">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_VISION_PROBLEM)}
        response={responses.miscVisionProblems}
        text="Do you currently have any other eye or vision problem that hasn't been covered already?"
      />
      <DescriptionQuestion
        response={responses.miscVisionProblemsDescription}
        text="Please provide more details about your eye or vision problem(s):"
      />
    </QuestionSection>
  );
}
