import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function DiabetesBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.diabetes === YesNo.YES} title="Diabetes">
      <YesNoQuestion
        response={responses.diabetes}
        text="Have you ever had diabetes?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION)}
        response={responses.diabetesCurrent}
        text="Do you still have diabetes?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_DIABETES)}
        response={responses.diabetesWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your diabetes?"
      />
      <YesNoQuestion
        response={responses.diabetesMedications}
        text="Do you take insulin or other medicines that can lower your blood sugar?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_HYPOGLYCEMIC_SYNCOPE_AT_WORK)}
        response={responses.diabetesSyncopeAtWork}
        text="Have you ever felt faint, really confused, or passed out at work because your blood sugar was too low?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_HYPOGLYCEMIC_SYNCOPE_S_SAFE_REMOVAL)}
        response={responses.diabetesWarningSigns}
        text="Can you tell when your blood sugar is getting low and get yourself to a safe place to eat before you feel faint or confused?"
      />
    </QuestionSection>
  );
}
