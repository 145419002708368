import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function AsbestosisBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.asbestosis === YesNo.YES} title="Asbestosis">
      <YesNoQuestion
        response={responses.asbestosis}
        text="Have you ever had asbestosis (the lung disease caused by asbestos fibers)?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ASBESTOSIS_SX_DURING_ADL)}
        response={responses.asbestosisSxDuringAdl}
        text="Does your asbestosis bother you when you do everyday things like chores, exercising, or walking up stairs?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_ASBESTOSIS)}
        response={responses.asbestosisWorseLastYear}
        text="Has your asbestosis gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_ASBESTOSIS)}
        response={responses.asbestosisSeverity}
        text="How bad do you and your doctor think your asbestosis is?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_ASBESTOSIS_ISSUES_IN_RESPIRATOR)}
        response={responses.asbestosisPriorRespiratorUseNotOk}
        text="Has your asbestosis ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_ASBESTOSIS_ISSUES_IN_RESPIRATOR)}
        response={responses.asbestosisFutureRespiratorUseExpectedNotOk}
        text="Do you think your asbestosis will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_ASBESTOSIS)}
        response={responses.asbestosisWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your asbestosis?"
      />
      <YesNoQuestion
        response={responses.asbestosisMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ASBESTOSIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.asbestosisMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_ASBESTOSIS)}
        response={responses.asbestosisHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your asbestosis?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_ASBESTOSIS_IMPACTING_WORK)}
        response={responses.asbestosisImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your asbestosis?"
      />
    </QuestionSection>
  );
}
