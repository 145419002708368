import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function BrokenRibsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.brokenRibs === YesNo.YES} title="Broken Ribs">
      <YesNoQuestion
        response={responses.brokenRibs}
        text="Have you ever had a broken rib?"
      />
      <YesNoQuestion
        response={responses.brokenRibsResolved}
        text="Are you completely better now from your broken rib(s)?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNRESOLVED_RIB_FX_C_DYSPNEA)}
        response={responses.brokenRibsCanExerciseWithoutSob}
        text="Can you do moderately intense exercise without getting out of breath?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_WORK_ISSUES_FROM_RIB_FX)}
        response={responses.brokenRibsCanPerformWorkFully}
        text="Do you think you can do your job without any problems or limitations caused by your broken rib(s)?"
      />
    </QuestionSection>
  );
}
