import { WithAuthenticatorProps, withAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';

import { LoadingIcon } from '../components/LoadingIcon';
import { EvaluationsList } from '../components/EvaluationsList';
import { QueryApiResponse } from '../types/general';

type RespiratorMedicalEvaluation = {
  creationTime: string;
  employee: {
    firstName: string;
    id: number;
    lastName: string;
    middleName: string;
  };
  id: number;
}

function HomepageInner({ signOut }: WithAuthenticatorProps) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reviewEvaluations, setReviewEvaluations] = useState<RespiratorMedicalEvaluation[]>([]);
  const [interviewEvaluations, setInterviewEvaluations] = useState<RespiratorMedicalEvaluation[]>([]);

  useEffect(() => {
    async function fetchReviews(accessToken: string) {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/respirator-medical-evaluations?status=PENDING_REVIEW&excludeTests=true&sortBy=creationTime`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const payload = await result.json() as QueryApiResponse<RespiratorMedicalEvaluation>;

      setReviewEvaluations(payload.data);
    }

    async function fetchInterviews(accessToken: string) {
      const result = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/respirator-medical-evaluations?status=PENDING_INTERVIEW&excludeTests=true&sortBy=creationTime`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const payload = await result.json() as QueryApiResponse<RespiratorMedicalEvaluation>;

      setInterviewEvaluations(payload.data);
    }

    async function fetchTodo() {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};

      if (accessToken === undefined) {
        setError(true);
        return;
      }

      const accessTokenString = accessToken.toString();

      try {
        await Promise.all([
          fetchReviews(accessTokenString),
          fetchInterviews(accessTokenString),
        ]);

        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    }

    void fetchTodo();
  }, []);

  if (error) {
    return <div>Error.</div>;
  }

  return (
    <div className="p-8">
      <div className="mx-auto max-w-7xl">
        <div className="text-right">
          <button
            type="button"
            className="rounded-md bg-[#3ac8aa] px-2.5 py-1.5 text-sm font-semibold text-teal-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500"
            onClick={signOut}
          >
            Sign Out
          </button>
        </div>
        {loading
          ? <LoadingIcon />
          : (
            <>
              <h1 className="font-display font-bold text-2xl mt-4" style={{
                background:  'linear-gradient(to bottom, #9238ff, #e501ff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>Needs Review</h1>
              <EvaluationsList evaluations={reviewEvaluations} />
              <h1 className="font-display font-bold text-2xl mt-16" style={{
                background:  'linear-gradient(to bottom, #9238ff, #e501ff)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>Pending Interview</h1>
              <EvaluationsList evaluations={interviewEvaluations} />
            </>
          )}
      </div>
    </div>
  );
}

export const Homepage = withAuthenticator(HomepageInner);
