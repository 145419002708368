export function EnumQuestion({
  choices,
  highlight = false,
  text,
  response,
}: {
  choices: Record<string, string>;
  highlight?: boolean;
  text: string;
  response: string | null;
}) {
  if (response === null) {
    return null;
  }

  return (
    <div><span className={highlight ? 'bg-orange-200' : ''}>{text} <span className="font-bold ml-2">{choices[response]}</span></span></div>
  );
}
