import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { PriorRespiratorUseProblemCause, YesNo, YesNoUnknown } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { PriorRespiratorUseProblemQuestion } from './PriorRespiratorUseProblemQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PriorRespiratorUseEyeIrritationBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.priorRespiratorUseEyeIrritation === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.priorRespiratorUseEyeIrritation === YesNo.YES} title="Eye Irritation">
      <YesNoQuestion
        response={responses.priorRespiratorUseEyeIrritation}
        text="Has using a respirator ever caused you eye irritation?"
      />
      <YesNoQuestion
        response={responses.priorRespiratorUseEyeIrritationCurrent}
        text="Do your eyes still get irritated when you use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EYE_IRRITATION_IMPACTING_SAFETY)}
        response={responses.priorRespiratorUseEyeIrritationImpactsWork}
        text="Does your eye irritation ever make it hard for you to see and cause safety issues at work?"
      />
      <PriorRespiratorUseProblemQuestion
        highlight={responses.priorRespiratorUseEyeIrritationCause === PriorRespiratorUseProblemCause.UNKNOWN && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_EYE_IRRITATION_AGGRAVATORS_AT_WORK)}
        response={responses.priorRespiratorUseEyeIrritationCause}
        text="What was the cause of your eye irritation?"
      />
      <YesNoQuestion
        highlight={(responses.priorRespiratorUseEyeIrritationAggravatorsAtWork === YesNoUnknown.YES || responses.priorRespiratorUseEyeIrritationAggravatorsAtWork === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_EYE_IRRITATION_AGGRAVATORS_AT_WORK)}
        response={responses.priorRespiratorUseEyeIrritationAggravatorsAtWork}
        text="Will you still be working around the thing that causes your eye irritation?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EYE_IRRITATION_DT_RESPIRATOR_S_ALTERNATIVE)}
        response={responses.priorRespiratorUseEyeIrritationAbleToUseAlternateRespirator}
        text="In your current job, are you able to use a style of respirator that does not cause you any eye irritation?"
      />
    </QuestionSection>
  );
}
