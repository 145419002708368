import { format } from 'date-fns/format';
import { Link } from 'react-router-dom';

type RespiratorMedicalEvaluation = {
  creationTime: string;
  employee: {
    firstName: string;
    id: number;
    lastName: string;
    middleName: string;
  };
  id: number;
}

export function EvaluationsList({
  evaluations,
}: {
  evaluations: RespiratorMedicalEvaluation[];
}) {
  return (
    <div className="bg-gray-900">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                      Entry Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {evaluations.map(({ creationTime, employee, id }) => (
                    <tr key={id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                        <Link className="text-[#3ac8aa]" to={`/charts/${employee.id}`}>{`${employee.lastName}, ${employee.firstName} ${employee.middleName}`.toUpperCase()}</Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{format(creationTime, 'MMMM d yyyy h:mm:ss a')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
