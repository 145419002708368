import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function ChestPainBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.chestPainBreathingDeeply === YesNo.YES} title="Chest Pain">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.PLEURITIC_CHEST_PAIN)}
        response={responses.chestPainBreathingDeeply}
        text="Do you currently have chest pain when you take deep breaths?"
      />
    </QuestionSection>
  );
}
