import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { PriorRespiratorUseProblemCause, YesNo, YesNoUnknown } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { PriorRespiratorUseProblemQuestion } from './PriorRespiratorUseProblemQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PriorRespiratorUseAnxietyBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.priorRespiratorUseAnxiety === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.priorRespiratorUseAnxiety === YesNo.YES} title="Anxiety">
      <YesNoQuestion
        response={responses.priorRespiratorUseAnxiety}
        text="Has using a respirator ever caused you anxiety?"
      />
      <YesNoQuestion
        response={responses.priorRespiratorUseAnxietyCurrent}
        text="Do you still have anxiety while using a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_SAFETY_ISSUES_IN_RESPIRATOR_DT_ANXIETY)}
        response={responses.priorRespiratorUseAnxietyTookOffRespiratorUnsafely}
        text="Has your anxiety ever made you take off your respirator too soon or in a way that wasn't safe?"
      />
      <PriorRespiratorUseProblemQuestion
        highlight={responses.priorRespiratorUseAnxietyCause === PriorRespiratorUseProblemCause.UNKNOWN && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_ANXIETY_TRIGGERS_AT_WORK)}
        response={responses.priorRespiratorUseAnxietyCause}
        text="What was the cause of your anxiety?"
      />
      <YesNoQuestion
        highlight={(responses.priorRespiratorUseAnxietyCurrentlyWorksWithCause === YesNoUnknown.YES || responses.priorRespiratorUseAnxietyCurrentlyWorksWithCause === YesNoUnknown.UNKNOWN) && flags.includes(RespiratorMedicalEvaluationFlag.POSSIBILITY_OF_ANXIETY_TRIGGERS_AT_WORK)}
        response={responses.priorRespiratorUseAnxietyCurrentlyWorksWithCause}
        text="Will you still be working around the thing that causes your anxiety?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ANXIETY_DT_RESPIRATOR_S_ALTERNATIVE)}
        response={responses.priorRespiratorUseAnxietyAbleToUseAlternateRespirator}
        text="In your current job, are you able to use a style of respirator that does not cause you any anxiety?"
      />
    </QuestionSection>
  );
}
