import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function ColorBlindnessBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.colorBlindness === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.colorBlindness === YesNo.YES} title="Color Blindness">
      <YesNoQuestion
        response={responses.colorBlindness}
        text="Are you color blind?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.COLOR_BLINDNESS_IMPACTING_SAFETY)}
        response={responses.colorBlindnessHasTroubleSeeingWarnings}
        text="Does being color blind make it hard for you to see dangers or warning signs?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.COLOR_BLINDNESS_ASSISTANCE_UNAVAILABLE)}
        response={responses.colorBlindnessWillHaveHelpSelectingColorCodedParts}
        text="If you need help picking the right color-coded parts for your respirator (like colored cartridges) at work, would there be someone there to help you?"
      />
    </QuestionSection>
  );
}
