import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PneumoniaBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.pneumonia === YesNo.YES} title="Pneumonia">
      <YesNoQuestion
        response={responses.pneumonia}
        text="Have you ever had pneumonia?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNRESOLVED_PNEUMONIA)}
        response={responses.pneumoniaResolved}
        text="Is your pneumonia completely gone?"
      />
    </QuestionSection>
  );
}
