import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PriorRespiratorUseOtherProblemsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.priorRespiratorUseMiscProblems === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.priorRespiratorUseMiscProblems === YesNo.YES} title="Other Respirator Problems">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_PROBLEM_IN_RESPIRATOR)}
        response={responses.priorRespiratorUseMiscProblems}
        text="Has using a respirator ever caused you any other problems not mentioned above?"
      />
      <DescriptionQuestion
        response={responses.priorRespiratorUseMiscProblemsDescription}
        text="Please provide more details about the problem(s) that you've had while using a respirator:"
      />
    </QuestionSection>
  );
}
