import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function MedicationsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.miscLungMedications === YesNo.YES || responses.miscHeartMedications === YesNo.YES} title="Medications">
      <YesNoQuestion
        highlight={responses.miscLungMedications === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_LUNG_CONDITION)}
        response={responses.miscLungMedications}
        text="Do you currently take any medicines for breathing or lung problems that were not covered in the questions above?"
      />
      <DescriptionQuestion
        response={responses.miscLungMedicationsDescription}
        text="Please provide more details about your lung problem that wasn't addressed in earlier questions:"
      />
      <YesNoQuestion
        highlight={responses.miscHeartMedications === YesNo.YES && flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_HEART_CONDITION)}
        response={responses.miscHeartMedications}
        text="Do you currently take any medicines for heart problems that were not covered in the questions above?"
      />
      <DescriptionQuestion
        response={responses.miscHeartMedicationsDescription}
        text="Please provide more details about your heart problem that wasn't addressed in earlier questions:"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ADVERSE_EFFECTS_OF_UNSPECIFIED_MEDS)}
        response={responses.miscMedicationsAdverseEffects}
        text={`Do your ${responses.miscHeartMedications === YesNo.NO ? 'lung' : responses.miscLungMedications === YesNo.NO ? 'heart' : 'lung or heart'} medicines make you feel sleepy, confused, or stop you from doing your job safely?`}
      />
    </QuestionSection>
  );
}
