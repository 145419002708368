import { Severity } from '../lib/general';

import { EnumQuestion } from './EnumQuestion';

export function SeverityQuestion({
  highlight = false,
  text,
  response,
}: {
  highlight?: boolean;
  text: string;
  response: Severity | null;
}) {
  return <EnumQuestion
    choices={{
      [Severity.MILD]: 'MILD',
      [Severity.MODERATE]: 'MODERATE',
      [Severity.SEVERE]: 'SEVERE',
    }}
    highlight={highlight}
    text={text}
    response={response}
  />
}
