import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function CoughBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.coughProducingPhlegm === YesNo.YES || responses.coughWakingEarly === YesNo.YES || responses.coughWhenLying === YesNo.YES || responses.coughBloodyInLastMonth === YesNo.YES} title="Cough">
      <YesNoQuestion
        response={responses.coughProducingPhlegm}
        text="Do you currently have a cough that produces phlegm (thick sputum)?"
      />
      <YesNoQuestion
        response={responses.coughWakingEarly}
        text="Do you currently have a cough that wakes you early in the morning?"
      />
      <YesNoQuestion
        response={responses.coughWhenLying}
        text="Do you currently have a cough that occurs mostly when you are lying down?"
      />
      <YesNoQuestion
        response={responses.coughBloodyInLastMonth}
        text="Have you coughed up blood in the last month?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_COUGH)}
        response={responses.coughSeverity}
        text="How bad do you think your cough is?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_COUGHING_FITS)}
        response={responses.coughFitsDifficultToBreatheMoreThan10Seconds}
        text="Do you ever have really bad coughing fits that make it hard to breathe or last for more than 10 seconds?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_COUGH_ISSUES_IN_RESPIRATOR)}
        response={responses.coughPredictsDifficultyUsingRespirator}
        text="Do you think your cough or phlegm will make it hard to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_REQUIRED_HIGH_RISK_ENVIRONMENT)}
        response={responses.coughMedicationRequiredForFits}
        text="Do you need to take medicine, like an inhaler, to stop coughing fits?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.COUGH_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.coughMedicationAccessibleAtWork}
        text="Will you be able to get to your medicine and take it while you're at work?"
      />
    </QuestionSection>
  );
}
