import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { SeverityQuestion } from './SeverityQuestion';
import { YesNoQuestion } from './YesNoQuestion';

export function ChronicBronchitisBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.chronicBronchitis === YesNo.YES} title="Chronic Bronchitis">
      <YesNoQuestion
        response={responses.chronicBronchitis}
        text="Have you ever had chronic bronchitis?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.CHRONIC_BRONCHITIS_SX_DURING_ADL)}
        response={responses.chronicBronchitisSxDuringAdl}
        text="Does your chronic bronchitis bother you when you do everyday things like chores, exercising, or walking up stairs?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSTABLE_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisWorseLastYear}
        text="Has your chronic bronchitis gotten worse in the last year?"
      />
      <SeverityQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.SEVERE_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisSeverity}
        text="How bad do you and your doctor think your chronic bronchitis is?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR)}
        response={responses.chronicBronchitisPriorRespiratorUseNotOk}
        text="Has your chronic bronchitis ever made it difficult for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.EMPLOYEE_PREDICTS_CHRONIC_BRONCHITIS_ISSUES_IN_RESPIRATOR)}
        response={responses.chronicBronchitisFutureRespiratorUseExpectedNotOk}
        text="Do you think your chronic bronchitis will make it hard for you to use a respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your chronic bronchitis?"
      />
      <YesNoQuestion
        response={responses.chronicBronchitisMedications}
        text="Do you take any rescue medicines like albuterol when you have symptoms?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.CHRONIC_BRONCHITIS_RESCUE_MEDS_INACCESSIBLE_AT_WORK)}
        response={responses.chronicBronchitisMedicationsAccessibleAtWork}
        text="When you're at work, can you get to your medicines easily and take them whenever you need to?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_HOSPITAL_STAY_FOR_CHRONIC_BRONCHITIS)}
        response={responses.chronicBronchitisHospitalStayLast2Years}
        text="In the last two (2) years, have you had to stay in the hospital because of your chronic bronchitis?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_CHRONIC_BRONCHITIS_IMPACTING_WORK)}
        response={responses.chronicBronchitisImpactedWorkLast2Years}
        text="In the last two (2) years, have you had to leave work or stop doing a task early because of your chronic bronchitis?"
      />
    </QuestionSection>
  );
}
