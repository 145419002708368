import { ReactNode } from 'react';

export function QuestionSection({
  children,
  highlight = false,
  title,
}: {
  children: ReactNode;
  highlight?: boolean;
  title: string;
}) {
  return (
    <div className={`rounded-md p-4 ${highlight ? 'bg-yellow-100': ''}`}>
      <div className="text-lg font-bold mb-2 underline">{title}</div>
      {children}
    </div>
  );
}
