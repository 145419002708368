import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { DescriptionQuestion } from './DescriptionQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function OtherHearingProblemsBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.miscHearingProblems === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.miscHearingProblems === YesNo.YES} title="Other Hearing Problems">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_HEARING_PROBLEM)}
        response={responses.miscHearingProblems}
        text="Do you currently have any other hearing or ear problem that wasn't covered in the questions above?"
      />
      <DescriptionQuestion
        response={responses.miscHearingProblemsDescription}
        text="Please provide more details about your hearing problem(s):"
      />
    </QuestionSection>
  );
}
