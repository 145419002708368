import { useId } from 'react';

export function TextAreaField({
  disabled = false,
  maxLength,
  name,
  onChange,
  placeholder,
  tabIndex,
  value,
}: {
  disabled?: boolean;
  maxLength?: number;
  name: string;
  onChange: (value: string) => void;
  placeholder?: string;
  tabIndex?: number;
  value: string;
}) {
  const id = useId();

  return (
    <textarea
      className={`
        ${disabled ? 'bg-gray-100' : ''}
        block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset resize-none
        focus:ring-2 focus:ring-inset
      `}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      name={name}
      onChange={(evt) => {
        onChange(evt.target.value);
      }}
      placeholder={placeholder}
      rows={5}
      tabIndex={tabIndex}
      value={value}
    />
  );
}
