import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { ImplantableCardiacDeviceType, YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { EnumQuestion } from './EnumQuestion';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function ImplantableCardiacDevicesBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.implantableCardiacDevice === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.implantableCardiacDevice === YesNo.YES} title="Implantable Cardiac Devices">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.IMPLANTABLE_CARDIAC_DEVICE_IDLH)}
        response={responses.implantableCardiacDevice}
        text="Do you have an implantable cardiac device such as a pacemaker or automated implantable cardioverter-defibrillator (AICD)?"
      />
      <EnumQuestion
        choices={{
          [ImplantableCardiacDeviceType.PACEMAKER]: 'PACEMAKER',
          [ImplantableCardiacDeviceType.AICD]: 'AICD',
          [ImplantableCardiacDeviceType.OTHER]: 'OTHER',
        }}
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.UNSPECIFIED_IMPLANTABLE_CARDIAC_DEVICE) || flags.includes(RespiratorMedicalEvaluationFlag.PACEMAKER_COLLISION_PRONE_ENVIRONMENT) || flags.includes(RespiratorMedicalEvaluationFlag.AICD_STRONG_MAGNETIC_ENVIRONMENT)}
        response={responses.implantableCardiacDeviceType}
        text="Select the type of implantable cardiac device you have:"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.AICD_STRONG_MAGNETIC_ENVIRONMENT)}
        response={responses.implantableCardiacDeviceWorksAroundStrongMagneticFields}
        text="At your job, are you around strong magnetic fields?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.PACEMAKER_COLLISION_PRONE_ENVIRONMENT)}
        response={responses.implantableCardiacDeviceWorksWithPossibilityOfCollisions}
        text="At your job, is there a chance of having collisions where people or objects can bump into you hard?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.ARRHYTHMIA_P_CARDIAC_DEVICE_IMPLANT)}
        response={responses.implantableCardiacDeviceFixedArrhythmia}
        text={`Did your ${responses.implantableCardiacDeviceType === ImplantableCardiacDeviceType.AICD ? 'automated implantable cardioverter-defibrillator' : responses.implantableCardiacDeviceType === ImplantableCardiacDeviceType.PACEMAKER ? 'pacemaker' : 'implantable cardiac device'} fix your ${responses.arrhythmia === YesNo.YES ? 'arrhythmia' : 'irregular heartbeat'}?`}
      />
    </QuestionSection>
  );
}
