import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function PriorRespiratorUseBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection
      highlight={
        responses.priorRespiratorUse === YesNo.NO && (
          flags.includes(RespiratorMedicalEvaluationFlag.NO_PROVEN_SAR_USE_SINCE_LUNG_CANCER_REMISSION)
        )
      }
      title="Prior Respirator Use"
    >
      <YesNoQuestion
        highlight={
          responses.priorRespiratorUse === YesNo.NO && (
            flags.includes(RespiratorMedicalEvaluationFlag.NO_PROVEN_SAR_USE_SINCE_LUNG_CANCER_REMISSION)
          )
        }
        response={responses.priorRespiratorUse}
        text="Have you used a respirator before?"
      />
    </QuestionSection>
  );
}
