import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function SmokingHistoryBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.smoker === YesNo.YES} title="Smoking History">
      <YesNoQuestion
        highlight={responses.smoker === YesNo.YES && (flags.includes(RespiratorMedicalEvaluationFlag.GTE_4_CARDIAC_RISK_FACTORS_MODERATE_EXERTION) || flags.includes(RespiratorMedicalEvaluationFlag.SMOKER_C_MEDIUM_RISK_PTX_HX))}
        response={responses.smoker}
        text="Do you currently smoke tobacco, or have you smoked tobacco in the last month?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.BREATHING_ISSUES_DT_SMOKING)}
        response={responses.smokerTroubleBreathing}
        text="Do you have trouble breathing because of your smoking habit?"
      />
    </QuestionSection>
  );
}
