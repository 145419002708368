import { PriorRespiratorUseProblemCause } from '../lib/general';

import { EnumQuestion } from './EnumQuestion';

export function PriorRespiratorUseProblemQuestion({
  highlight = false,
  text,
  response,
}: {
  highlight?: boolean;
  text: string;
  response: PriorRespiratorUseProblemCause | null;
}) {
  return <EnumQuestion
    choices={{
      [PriorRespiratorUseProblemCause.RESPIRATOR]: 'THE RESPIRATOR',
      [PriorRespiratorUseProblemCause.WORK_ENVIRONMENT]: 'SOMETHING AT WORK',
      [PriorRespiratorUseProblemCause.UNKNOWN]: 'UNKNOWN',
    }}
    highlight={highlight}
    text={text}
    response={response}
  />
}
