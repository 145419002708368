import { ReactNode } from 'react';

type SelectOption<T> = {
  label: ReactNode;
  value: T;
};

export function StackedCheckboxField<T extends string>({
  notaValue,
  onChange,
  options,
  value,
}: {
  notaValue?: T;
  onChange: (value: T[]) => void;
  options: SelectOption<T>[];
  value: T[];
}) {
  return (
    <fieldset>
        <div className="space-y-4">
          {options.map((option) => {
            const checked = value.includes(option.value);

            return (
              <div
                className={`
                  ${checked ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300'}
                  relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm
                  focus:outline-none sm:flex sm:justify-between
                `}
                key={option.value}
                onClick={() => {
                  if (option.value === notaValue) {
                    if (value.length === 1 && value[0] === notaValue) {
                      onChange([]);
                    } else {
                      onChange([option.value]);
                    }
                  } else if (!checked) {
                    onChange([...value, option.value].filter((val) => val !== notaValue));
                  } else {
                    onChange(value.filter((val) => val !== option.value));
                  }
                }}
              >
                {option.label}
              </div>
            );
          })}
        </div>
    </fieldset>
  );
}
