import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function StrokeBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  return (
    <QuestionSection highlight={responses.stroke === YesNo.YES} title="Strokes">
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_CVA_HIGH_RISK_ENVIRONMENT) || flags.includes(RespiratorMedicalEvaluationFlag.HX_CVA_MODERATE_EXERTION) || (flags.includes(RespiratorMedicalEvaluationFlag.ARRHYTHMIA_C_STRUCTURAL_HEART_DISEASE) && responses.stroke === YesNo.YES)}
        response={responses.stroke}
        text="Have you ever had a stroke?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.RECENT_CVA)}
        response={responses.strokeMostRecentMoreThan2YearsAgo}
        text="Was your most recent stroke more than 2 years ago?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.LASTING_DEFICITS_FROM_CVA)}
        response={responses.strokeLastingEffectsPreventingRespiratorUse}
        text="Do you have any lasting complications from your stroke that would prevent you from wearing a tight-fitting respirator?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.HX_TIA_AFTER_CVA)}
        response={responses.strokeSubsequentTia}
        text="Since your stroke, have you had any Transient Ischemic Attacks (TIAs or mini-strokes)?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.WORK_ACCOMMODATIONS_FOR_CVA)}
        response={responses.strokeWorkAccommodations}
        text="Do you have any, or will you be requesting any, medical restrictions or accommodations for your past stroke(s)?"
      />
    </QuestionSection>
  );
}
