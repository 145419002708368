import { RespiratorMedicalEvaluationFlag } from '../lib/flags';
import { YesNo } from '../lib/general';
import { HealthHistoryStageResponses } from '../types/questionnaire';
import { QuestionSection } from './QuestionSection';
import { YesNoQuestion } from './YesNoQuestion';

export function VisionCorrectionBlock({
  flags,
  responses,
}: {
  flags: RespiratorMedicalEvaluationFlag[];
  responses: HealthHistoryStageResponses;
}) {
  if (responses.visionCorrection === null) {
    return null;
  }

  return (
    <QuestionSection highlight={responses.visionCorrection === YesNo.YES} title="Vision Correction">
      <YesNoQuestion
        response={responses.visionCorrection}
        text="Do you currently wear glasses or contact lenses?"
      />
      <YesNoQuestion
        highlight={flags.includes(RespiratorMedicalEvaluationFlag.VISION_CORRECTION_NOT_USABLE_AT_WORK)}
        response={responses.visionCorrectionCanWearAndSeeAtWork}
        text="Can you see well enough to do your job and if needed are you able to wear your glasses or contact lenses at work?"
      />
    </QuestionSection>
  );
}
